.ProgressBar {
  &__label {
    font-size: 1.27em;
  }

  &__wrapper {
    margin-top: 1em;
  }

  &__progress-bar {
    width: 100%;
    background-color: #b6d0f5;
    border-radius: 1.6em;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  &__progress-bar-fill {
    display: block;
    height: 2.8em;
    background-color: #f0c765;
    border-top-left-radius: 1.6em;
    border-bottom-left-radius: 1.6em;
    transition: width var(--ac-transition-slow) ease-in-out;
  }

  &__progress-bar--icon {
    margin-top: 0.3em;
  }
}
