.DataVoteBlock {
    &__group {
        &.ui.card {
            width: 100% !important
        }

        h2.header {
            margin-bottom: 0em !important;
        }

        .subtitle.description {
            font-size: 0.9em;
            margin-top: 0.3em !important;
        }

        &.has-value {
            .DataVotes__item {
                cursor: initial !important;
            }
        }
    }

    &__item {
        cursor: pointer;
        width: 100%;
        margin: 8px 0px;
        padding: 8px;
        font-weight: bold;
        border-radius: 8px;
        border: 1px solid #ccc;
        display: flex;
        justify-content: space-between;

        &.is-selected {
            font-weight: bold;
            background-color: #ddd;
        }

        i.icon.checked {
            float: right;
        }
    }

    &__vote {
        text-align: center;
        margin-bottom: 8px;
    }
}