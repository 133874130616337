.workshop--registrationBlock--session .registration-button {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    padding: 0.5em 0 0 0 !important;
    &__hour {
        margin: 0 auto;
        padding-bottom: 0.5em;
    }
    &__infos {
        display: flex;
        margin: auto;
        flex-direction: row;
        font-size: 0.8em;
        background-color: #efefef;
        width: 100%;
        color: #333;
        padding: 0.5em;
        height: 100%;
        justify-content: space-evenly;
    }
    &__quota {
        background-color: #efefef;
        width: 100%;
        color: #333;
    }
    &__full {
        margin: 0.5em 0;
    }
}
