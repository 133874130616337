.workshop-group {
    &.ui.segment {
        border: none;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    .ui.button {
        border-radius: 20px;
    }

    &__title.ui.header {
        margin: 0px;
        margin-bottom: 4px;
    }

    &__title {
        margin-top: 0px;
    }

    &__cards {
        &.ui.stackable {
            .ui.card {
                // padding: 10px !important;
                display: flex;
            }
        }

        &.compact.ui.cards {
            margin: -0.5em;

            .ui.card {
                width: calc(50% - 1em);
                margin: 0.5em;
            }
        }

        &__card {
            &.ui.card {
                // box-shadow: rgba(0, 0, 0, .15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
                display: flex;
                flex-direction: row;
            }

            &--simple {
                .content {
                    flex: 1.7 !important;
                    padding: 0px;

                    .image-title {
                        display: flex;
                        align-items: center;

                        margin-bottom: 4px;
                    }

                    .image {
                        max-height: 40px;
                        margin-right: 5px;
                        object-fit: contain;
                        background-color: white;
                    }

                    .title {
                        margin: 0px;
                    }

                    .subtitle {
                        font-size: 13px;
                        color: #999;
                        margin: 0px;
                    }

                    .description {
                        margin-top: 10px;
                        font-size: 13px;
                    }
                }

                .registration {
                    flex: 1.3;
                    padding: 1em;
                    display: flex;
                    flex-direction: column;

                    .session-count {
                        font-size: 11px;
                        color: #999;
                    }

                    .next-session {
                        // flex: 1;

                        .label {
                            font-weight: bold;
                        }
                    }

                    .datetime {
                        margin-bottom: 10px;
                        text-align: center;

                        span {
                            display: block;
                        }
                    }

                    .access-button {
                        margin-top: 10px;
                    }

                    button.view-sessions {
                        margin-top: 10px;
                        border-radius: 20px;
                        padding: 10px 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .ui.stackable.cards>.card {
        width: 100% !important;
    }

    .workshop-group {
        &__cards {
            &__card {
                &.ui.card {
                    margin-left: 0px !important;
                    margin-right: 0px !important;
                    width: 100% !important;
                }
            }
        }
    }
}