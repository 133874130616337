.ctaTile--block > .ui.segment {
  padding: 20px;
}

.ui.segment.segment--background-image {
  background-position: center;
  background-size: cover;

  .ui.header {
    color: rgba(255, 255, 255, 0.9);

    .sub.header {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.CTATile {
  cursor: pointer;

  &.ui.segment {
    display: block;
    background-color: rgba(255, 255, 255, 0.7);

    &:not(.segment--background-image):hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &--variant {
    &-square {
      display: block;
      padding: 0 !important;
      overflow: hidden;
    }

    &-image {
      overflow: hidden;
      display: block;

      &.ui.segment {
        padding: 0;
      }

      img {
        display: block;
      }

      .CTATile__content {
        padding: 1em;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &--animation-scale {
    .container--square {
      transform: scale(1);
      transition: transform 0.5s ease-in-out;
    }

    &:hover {
      .container--square {
        transform: scale(1.1);
      }
    }
  }

  .container--square {
    background-size: cover;
    background-position: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;

      .ui.icon.header {
        display: block;
      }
    }
  }

  &__content {
    &--below {
      text-align: center;
    }
  }
}
