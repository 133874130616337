@keyframes unlock-badge {
  50% {
    transform: scale(1.1);
  }
}

.AchievementsBlock {
  &.ui.segment {
    margin-top: 2em !important;
  }

  .ui.header {
    margin-top: 0.5em;
    margin-left: 2em;
    margin-bottom: 1.4em;
  }

  .row-badges {
    margin-left: 2.5em !important;
    margin-right: 2em !important;
    padding-bottom: 0.4em !important;
  }

  @media (max-width: 767px) {
    .row-badges {
      display: flex;
    }
  }

  &__badges {
    margin-bottom: 1.5em;
    display: flex;
  }

  &__badge {
    padding: 0 !important;
    margin-right: 0.4em;
  }

  &__badge--unlocked > img {
    animation: unlock-badge 1s linear both;
  }

  &__ProgressBar {
    text-align: center;
    padding-left: 2em !important;
    padding-right: 3.5em !important;
    margin-top: 6em;

    &__label {
      font-size: 1.27em;
    }

    .ProgressBar__progress-bar--icon {
      transition: var(--ac-transition-slow) margin-left;
    }
  }
}
