.countdown {
  text-align: center;
  display: flex;
  justify-content: center;

  .clock {
    margin: 10px;
    display: inline-block;
    padding: 30px 30px 25px 30px;
    border-radius: 5px;
    font-weight: 400;
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.circle1 {
      border-radius: 50%;
    }
  }

  .clock-counter {
    font-weight: 600;
    font-size: 70px;
    line-height: 0.8;
  }

  .clock-label {
    font-size: 14px;
  }

  @media (max-width: 710px) {
    .clock {
      height: 105px;
      width: 105px;
    }

    .clock-counter {
      font-size: 50px;
    }
  }

  @media (max-width: 480px) {
    .clock {
      margin: 4px;
      height: 73px;
      width: 73px;
    }

    .clock-counter {
      font-weight: 400;
      font-size: 30px;
      line-height: 0.8;
    }

    .clock-label {
      font-size: 12px;
    }
  }
}