tr.appointment {
  td.title {
    .location {
      font-size: 0.85em;
      color: #555;
    }
  }

  td.actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
