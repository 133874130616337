.WorkshopModal {
  &.ui.modal .scrolling.content {
    max-height: calc(80vh);
  }

  &__header {
    min-height: 50px;
  }

  &__content {
    height: 80vh;
    max-height: calc(80vh);
    padding: 0px !important;
    display: flex !important;
    flex-direction: column;

    .sesion-duration,
    .session-location,
    .session-quota {
      display: inline-block;
      margin-left: 1em;
    }

    .container {
      padding: 10px 40px;
    }

    .logos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }

    .title {
      margin-top: 0px;
    }

    .description {
      font-size: 1.2em;
      margin-top: 16px;
    }

    .speakers {
      display: flex;
      align-items: center;

      .avatars {
        margin-left: 10px;
      }
    }

    .tags {
      margin-top: 10px;

      &--tag {
        margin-right: 8px;
        border-radius: 20px;
        font-weight: normal;
      }
    }

    .block--video {
      margin-top: 10px;
    }

    .footer {
      padding: 20px 40px;

      .controls {
        // margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .previous,
      .next {
        cursor: pointer;
        font-weight: bold;
        font-size: 1.1em;

        .ui.button {
          color: white;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }

      .actions {
        .ui.icon.button.action {
          padding-right: 2.5em !important;
          padding-left: 1em !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ui.modal {
    .WorkshopModal__content {
      &.content {
        padding: 0px !important;
      }

      .container {
        padding: 1rem !important;
      }

      .footer {
        .actions {
          .ui.button.action {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
