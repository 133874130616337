.UsersBlock {
    &__Search {
        margin: auto;
        margin-bottom: 1.3em;
    }

    &__ShowMore {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.7em;

        .ui.button {
            margin: 0px;
            cursor: pointer;

            i.icon {
                margin: 0px;
            }
        }

        .divider {
            flex: 1;
            height: 1px;
            background-color: black;
        }
    }
}