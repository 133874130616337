.screen-auth--variant-aside {
  display: flex;
  flex-direction: row;
  height: 100%;

  > .page-background {
    flex: 1;
    position: relative;
  }

  > .screen-auth__sidebar {
    max-width: 100%;
    width: 500px;
    background: white;
    box-shadow: 0 0 20px rgba(34, 36, 38, 0.15);
  }
}