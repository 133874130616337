.apply-opportunity {
    margin: 10px 0px;

    &__apply.ui.button {
        flex: 1;
        border-radius: 20px;
    }

    &__time.ui.label {
        flex: 1;
        text-align: center;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    &__no-slots {
        padding: 20px;
        border-radius: 8px;
        background-color: #fdc300;
    }

    .appointments-block {
        margin-top: 20px;
    }
}