.ui.category.search>.results .category>.name {
  vertical-align: top;
}

.SearchRenderer {
  & .ui.input {
    width: 100%;
  }

  &.ui.search>.results .result .image+.content {
    margin: 0;
  }

  &.ui.scrolling.dropdown .menu,
  &.ui.search .results {
    max-height: 70vh;
    overflow: auto;
  }

  &.ui.category.search > .results .category .result {
    padding: 0;
  }
}