.IconGridItems {
  // overflow: hidden;
  margin-bottom: 1em;
}

.IconGridItem {
  overflow: hidden;
  position: relative;

  &__Wrapper {
    position: relative;
  }

  &__Icon {
    > i {
      font-size: 1em;
      line-height: 1;
      text-align: center;
      margin: 0;
    }

    > img {
      width: 1em;
      height: 1em;
    }
  }

  &__RightIcon {
    display: none;
  }
}
