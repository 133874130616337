.RoomingBlock {

    margin-top: 20px !important;
    border: none !important;

    .ui.segment {
        background: rgba(255, 255, 255, 0.47);
    }

    &__requests {
        margin-bottom: 20px;
    }

    &__invitations {
        margin-bottom: 20px;
    }
}