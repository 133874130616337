.BackToTopArrow {
  transition: transform var(--ac-transition-normal) ease;
  transform: translateY(80px);

  &.is-visible {
    transform: translateY(0);
  }

  i.icon {
    color: white;
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
  }
}
