$bar-size: 3rem;

.FloatingChat {
  position: fixed;
  bottom: 0;
  right: 1rem;

  z-index: 200;

  display: flex;
  flex-direction: column;
  height: calc(55vh + #{$bar-size});
  max-height: 100vh;
  width: 25rem;
  background-color: #fff;
  box-shadow: -2px -2px 5px rgba(255, 255, 255, 0.25);

  &__Bar {
    height: $bar-size;
    color: #fff;
    font-size: 1rem;
    line-height: 1;
    padding: 0.5rem;
    border: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  /** Open/Close Animation **/
  transform: translateY(calc(100% - #{$bar-size}));

  &,
  &__ToggleButton {
    transition: transform var(--ac-transition-fast) ease-in-out;
  }
  &__ToggleButton {
    transform: rotate(0);
  }
  &-opened {
    transform: translateY(0);

    & .FloatingChat__ToggleButton {
      transform: rotate(180deg);
    }
  }

  /** Mobile overload **/

  @media (max-width: 767px) {
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: auto;
  }
}
