.item--workshop {

  i.icon {
    line-height: 20px;
  }

  .header {
    font-size: 14px !important;
  }

  .start-date {
    font-size: 11px;
    text-transform: uppercase // font-weight: bold;
  }

  .end-date,
  .timezone {
    font-size: 10px;
    text-transform: uppercase
  }

  .image {
    position: relative;
    padding-right: 0px !important;
    height: 100% !important;
  }

  .overlay {
    visibility: hidden;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.4);

    .actions {
      .action.ui.button {
        padding-right: 2.5em !important;
        padding-left: 1em !important;
      }
    }
  }

  &:hover {
    .overlay {
      visibility: visible;
    }
  }

}