.ClickableMap {
  position: relative;

  > img {
    display: block;
  }

  .clickable-svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
