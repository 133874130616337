.ui.card.notification {
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  background-color: #fff;
  border-left: solid 0.24em var(--ac-color-primary) !important;

  .content {
    padding: 0.7em 1.5em;

    .type {
      color: var(--ac-color-primary);
    }

    .type-and-status {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.6em;

      .type {
        font-style: italic;
        text-transform: uppercase;
        font-size: 0.7em;
      }

      .ui.label.unread {
        color: white;
        background-color: red;
        border-radius: 20px !important;
      }
    }

    .date {
      margin-top: 0.2em;
      font-size: 0.7em;
      font-style: italic;
    }
  }
}

.ui.card.notification:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  left: -1px;
  z-index: var(--ac-index-hidden);
}
