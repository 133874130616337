.workshop--commentsBlock {
    &.anonymous {
        .comment {
            .metadata {
                margin-left: 0px;
            }
        }
    }

    .ui.segment.segment--content {
        display: flex;
        flex-direction: column;
    }

    .comment {

        &--container {
            display: flex;
        }

        &--input.ui.input {
            width: 100%;
            border-bottom: 2px solid black;
            outline: none;
            margin-left: 10px;

            >input {
                background: transparent;
                border: none;
                border-radius: 0px;
                padding: 0px;
            }
        }

        &--action {
            text-align: right;
            float: right;
            display: flex;
            align-items: center;
        }

        &--send--button {
            margin-top: 6px;
            margin-bottom: 10px;
        }
    }

    .user-bubble {
        min-width: 40px;
        height: 40px;
    }

    .comments {
        &--container {
            min-width: 100%;
            margin-top: 10px;
        }
    }


}