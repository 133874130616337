.UserAvatarModal {
    &.ui.modal {
        color: white;
        border-radius: 16px;

        .content,
        .header,
        .actions {
            background: #1d2226;
        }

        .header {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-size: 1.3em;
            color: white;
            font-weight: normal;
        }

        .actions {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top: 1px solid #333;
        }

        .close.icon {
            color: white;
            top: 4px;
            right: 0px;
        }
    }

    &__Content {
        img {
            margin: auto;
            border-radius: 50%;
        }
    }
}