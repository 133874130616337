.list-details {
    &.ui.grid {
        margin: 0px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

        >.column {
            padding: 0px;
        }
    }

    &__header {
        padding: 12px;
        font-weight: bold;
    }

    &__search {
        padding: 10px;

        input {
            border-radius: 20px !important;
        }
    }

    &__paginator {
        &.ui.menu.pointing.secondary {
            display: flex;
            justify-content: center;
            margin: 4px auto;
            max-width: max-content;
            box-shadow: none;
            border: none;

            .item {
                outline: none;
            }
        }
    }

    &__items {
        overflow: auto;

        &__item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ddd;
            padding: 8px;

            .highlighter mark {
                background: red;
            }

            &.is-active {
                background-color: #f5f5f5;
            }

            &__image {
                margin-right: 8px;
            }

            &__content {
                flex: 1;
            }

            &__title {
                font-size: 1em;
                margin: 0px;
            }

            &__progress {
                width: 50px;
                margin-left: 4px;
                text-align: center;

                .appointment {
                    font-size: 11px;
                    font-weight: bold;
                }
            }
        }
    }

    &__details {
        max-height: 800px;
        overflow-y: auto;

        &__header {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .image {
                margin-right: 10px;
                max-height: 80px;
                object-fit: contain;
                max-width: 150px;
            }

            .title {
                margin: 0px;
            }

            .subtitle {
                margin: 0px;
                margin-top: 4px;
                color: #777;
            }

            &__links > * {
                color: #333;
                font-size: 12px;
                text-decoration: underline;
                display: block;
            }

            .content {
                flex: 1;
            }

            .percentage {
                max-width: 80px;
            }
        }

        &__html {
            margin-top: 15px;
        }

        &__matching {
            margin-top: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .field {
                flex: 1;
                font-size: 12px;
                padding: 4px;

                .label {
                    margin-top: 4px;
                    font-weight: bold;
                }
            }
        }

        &__appointments {
            margin-top: 10px;

            .ui.segment {
                border: none;
                box-shadow: none;
                padding-right: 0px;
            }

            .SlotDay {
                .ui.header {
                    font-size: 1em;
                }
            }

            .SlotGrid {
                .ui.grid .column {
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }

    }

    &__details {

        &.ui.segment {
            border: none;
            box-shadow: none;
        }
    }

}