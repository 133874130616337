@import "../../../../scss/helpers/positions";

.IconGridItem--variant-imageList {
  margin-bottom: 10px;

  .IconGridItem {
    &__Icon {
      margin-right: 8px;
    }

    &__Image {
      > img {
        width: 100%;
        height: 100%;
        @include pos-absolute(0, 0, 0, 0);
      }
    }

    &__Content {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), transparent);
      @include pos-absolute(0, 0, 0, 0);
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0.5em 1em;
      font-size: 1.5em;
      color: white;
    }

    &__Title {
      flex: 1;
    }

    &__RightIcon {
      display: block;
    }

    &__RightIcon > i.icon {
      margin-right: 0;
    }
  }
}
