.block--actions {
  margin: 1em 0;
}

.ui.segment.block-container > .block--actions {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ui.modal.modal--iframe {
  > .content {
    position: relative;

    // iframe {
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   border: 0;
    //   width: 100%;
    //   height: 100%;
    // }
  }
}
