.FormModal {
  &__Image {
    overflow: hidden;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.modal > .content.FormModal__Image {
    padding: 0 !important;
  }
}
