.ProfilePageV2 {
  .page--cms-container {
    > .content {
      padding: 0px;
      .ui.container {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        height: 0px;
        margin: -80px 1.4em 0px;
      }
    }
  }

  &--mode {
    &-desktop {
      .ProfilePageV2 {
        &__Header {
          min-height: 160px;
        }

        &__Content {
          background-color: transparent !important;
          box-shadow: none !important;

          .column {
            background-color: white;
            border-radius: 8px 8px 0px 0px;
            box-shadow: 0px 0px 32px 0px rgba(136, 152, 170, 0.15);
            margin: 0em 0.5em;

            &.infos {
              padding: 0px 1rem !important;
            }
          }
        }
      }
    }
    &-mobile {
      .ProfilePageV2 {
        &__Content {
          .column {
            padding: 0em !important;
          }
        }
      }
    }
  }
  &__Header {
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--ac-color-primary);
    min-height: 200px;

    h3.header.title {
      margin-top: 0px;
      color: white;
      margin-bottom: 4px;
    }

    .subtitle {
      color: #d1d1d1;
    }
  }
  &__Tabs {
    padding: 0em;
    margin-bottom: 1.4em;
  }

  &__Content {
    &.ui.grid {
      flex: 1;
      background-color: white;
      border-radius: 8px 8px 0px 0px;
      margin: -80px 1.4em 0px;
      box-shadow: 0px 0px 32px 0px rgba(136, 152, 170, 0.15);
      display: flex;
      padding: 0em 1em;
      flex-direction: column;
      height: 0px;

      .column {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .image-and-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: -40px;
      .action {
        cursor: pointer;
        position: absolute;
        &.qrcode {
          right: 20px;
        }
        &.plane {
          left: 20px;
        }
      }
      span.material-symbols-outlined {
        color: rgba(0, 0, 0, 0.87);
        background-color: #eaeaea;
        padding: 10px;
        border-radius: 20px;
      }
      .userAvatar {
        margin: auto;
        border-radius: 50%;
      }
    }
  }
}
