.item {

    &--classic {

        .category {
            font-weight: normal!important;
            margin-bottom: 10px !important;
        }

        .image {
            width: 350px;
            height: auto;
            margin-right: 20px;
            border-radius: 8px;
            z-index: 1;
            box-shadow: var(--ac-shadow-secondary), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
        }
    }

}