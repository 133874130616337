.reset-password-modal {
  @media (max-width: 480px) {
    .actions {
      display: flex;
      flex-direction: column-reverse;

      .ui.button.reset {
        margin-bottom: 0.5em;
      }
    }
  }
}
