.publication-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .publication-content {
    padding: 5px 20px;
    line-height: 20px;

    &__emotes-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &--end {
        justify-content: flex-end !important;
      }
    }
  }

  .publication-comments {
    flex: 1;
  }
}
