.ui.grid .column {
  .ui.segment.no-grid-margin {
    margin-left: -1em;
  }

  @media (max-width: 767px) {
    .ui.segment.no-grid-margin {
      margin: 0px !important;
    }
  }
}

.ui.animated.list:not(.horizontal) {
  overflow-x: hidden;
}

.ui.animated.list:not(.horizontal) > .item:hover {
  padding-left: 0em;
  transform: translateX(0.5em);
}

.ui.animated.list > .item {
  transition: var(--ac-transition-normal) color ease 0.1s, var(--ac-transition-normal) transform ease 0.1s,
    var(--ac-transition-normal) background-color ease 0.1s;
}

.ui.button.link {
  // transition: color 0.1s ease !important;
  border-radius: 0;
  font-weight: normal;
  margin: 0 !important;
  padding: 0em !important;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.6);
  background: none;
  text-align: left;

  &:hover {
    color: #333;
    background: none;
    text-decoration: underline;
  }
}

/* FIX BROKEN BOTTOM PADDING ACTION MODAL */
@media only screen and (max-width: 767px) {
  .ui.modal > .actions {
    padding-bottom: 1rem !important;
  }
  .ui.modal .actions > .button,
  .ui.modal .actions > .buttons {
    margin-bottom: 0;
  }
}
