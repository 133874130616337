@import "../../../scss/helpers/positions";

@keyframes scale__0_7__1 {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale__0_7__1_1 {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.1);
  }
}

.page--type-HomeVideo3DX {
  .menu--header {
    z-index: 99;
    display: flex;
    justify-content: center;

    .logo--main {
      position: fixed;
      top: 10px;
      left: 10px;
    }
  }

  .logo--secondary {
    max-height: 20vh;
  }

  .page-background {
    z-index: 0;
  }

  .page-background__wrapper--16_9 {
    > img {
      display: block;
      @include pos-absolute(0, 0, 0, 0);
      width: 100%;
      height: 100%;
    }
  }

  .menu {
    display: block;
    @include pos-absolute(0, 0, 0, 0);
    width: 100%;
    height: 100%;

    &__entry {
      position: absolute;
      display: block;
    }
  }

  .menu_entry {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;

    &__dot:hover {
      animation: scale__0_7__1_1 0.7s alternate infinite ease-in;
    }

    &__dot {
      background: white;
      border-radius: 50%;
      opacity: 0.95;
      animation: scale__0_7__1 1.5s alternate infinite ease-in;
    }

    &__label {
      position: absolute;
      color: white;
      text-transform: uppercase;
    }
  }
}
