.sidebar--burger-menu {
  &__header {
    cursor: pointer;
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
  }

  &__container {
    background: #fff;

    >.ui.menu {
      border: 0;
      box-shadow: none;
      text-align: center;
    }

    .dropdown--container {
      padding: 8px 16px;
    }

    .item {
      padding: 2em;
      letter-spacing: 0.05em;
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-start;

      .title {
        text-align: left;
      }
    }

    &--header {
      display: flex;
      flex-direction: row !important;
      width: 100%;
      position: relative;
      display: block;
      text-align: center;

      &__close-button {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__user {
        display: flex;
        align-items: center;
        text-align: left;

        .fullname {
          font-size: 1.3em
        }


        .logout {
          display: flex;
          align-items: center;
          text-align: left;
          cursor: pointer;
          margin-top: 10px;
          font-style: normal;

          img,
          i {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}