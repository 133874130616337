.TabbedMenu {
  &.ui.menu {
    margin-top: 1em;
    border-radius: 16px;
    max-height: 32px;
    height: 32px;
    min-height: unset;
    border: none;
    box-shadow: none;
    padding-bottom: 0px !important;
    background-color: #eaeaea;

    .item {
      opacity: 0.7;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.87) !important;

      &.active {
        opacity: 1;
        border-radius: 16px;
        background-color: var(--ac-color-primary);
        color: white !important;
      }

      &::before {
        background: transparent;
      }
    }
  }
}
