.animation {
  &.column--0 {
    &-enter {
      opacity: 0;
      transform: translateX(-10vw);
    }

    &-enter-active,
    &-enter-done {
      opacity: 1;
    }

    &-enter-active {
      transform: translateX(0);
      transition: opacity var(--ac-transition-very-slow), transform var(--ac-transition-very-slow);
    }
  }

  &.column--1 {
    &-enter {
      opacity: 0;
      transform: translateX(110vw);
    }

    &-enter-active,
    &-enter-done {
      opacity: 1;
    }

    &-enter-active {
      transform: translateX(0);
      transition: opacity 1.7s, transform 1.7s;
    }
  }
}
