$speakerSize: 128px;
$speakerLightSize: 110px;
$speakerLargeSize: 200px;

.ui.card.card--speaker {
  margin-top: $speakerSize * 0.5;
  text-align: center;
  // flex-grow: 1;
  margin-bottom: 32px;

  .tags {
    margin-top: 10px;
  }

  .email {
    &.bubble {
      background-color: white;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: calc(50% + 25px);
      margin-top: -25px;
      z-index: 1;

      i.icon {
        margin: 0px !important;
        height: auto;
        width: auto;
        font-size: 24px;
      }
    }
  }

  img.avatar {
    width: $speakerSize;
    height: $speakerSize;
    margin: 0 auto;
    margin-top: -$speakerSize * 0.5;
    border-radius: 50%;
    display: block;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(0, 0, 0, 0.2);

    &.large {
      width: $speakerLargeSize;
      height: $speakerLargeSize;
      margin-top: -$speakerLargeSize * 0.5;
    }

    &.light {
      width: $speakerLightSize;
      height: $speakerLightSize;
      margin-top: -$speakerLightSize * 0.5;
    }
  }

  .content {
    border-top: 0;
    flex: 1 0 auto;

    .field-email {
      word-break: break-all;
    }
  }

  .navlink {
    border-top: 0;
    flex-grow: 0;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .ui.card.card--speaker {
    margin-top: $speakerSize * 0.5 + 10;
  }
}
