.workshop-session-modal {
    .ui.button {
        border-radius: 0.5em;
    }

    &__card {

        &.ui.card {
            border: none;
            padding: 14px;
            // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .header {
                color: black;
                font-size: 1.3em;
            }

            &.is-active {
                box-shadow: 0 1px 3px 0 #AAA, 0 0 0 1px #AAA;
                background-color: #f1f1f1;
            }
        }
    }


    .meta.card__progress {
        width: 100%;

        .label {
            text-align: left;
        }

        .ui.indicating.progress {
            margin-bottom: 0px;
        }
    }
}