.ui.card.card--replay {
  max-width: 260px;

  .image {
    display: block;
    width: 100%;
  }

  .extra.button-box {
    border-top: 0px !important;
  }

  .action-buttons {
    background-color: var(--ac-color-secondary);
    border: 0 !important;
    margin: 0;
    padding: 10px;
    text-align: center;

    a {
      border-left: 0;
      color: #2185d0 !important;
    }

    a:hover {
      color: #1a6197 !important;
    }

    .ui.buttons {
      border: 0;
    }

    .know-more__button {
      flex: 1;
    }

    .download__button {
      border-left: 1px solid rgba(34, 36, 38, 0.15);
    }
  }
}

@media (max-width: 767px) {
  .ui.card.card--replay {
    max-width: 100%;
    width: 100%;
  }
}
