.ChatOptions {
  &.ui.popup {
    padding: 0px;
  }
  &__List {
    &.ui.list {
      .item {
        cursor: pointer;
        padding: 8px 10px;
      }
    }
  }
}
