.UpdateProfile {
  .ui.form {
    width: 100%;
  }
  .bottom-panel__content {
    justify-content: flex-start;
  }

  &__fields {
    width: 100%;
    flex: 1;
    height: 100%;
    overflow: scroll;
  }

  &__save {
    &.ui.button {
      width: 100%;
      border-radius: 20px;
    }
  }

  .UserAvatarImageUploader__Thumbnail {
    width: 100px !important;
    height: 100px !important;
  }

  &.rounded-modal {
    padding: 0em;
    text-align: left;
    overflow: initial;
    .button {
      margin: 0px !important;
    }
  }
}
