.UserProfileNetworkingBlock {
    width: 100%;
    margin: 20px 0px;

    &__card {
        &.ui.card {
            width: 100%;
            text-align: center;

            .thumbnail {
                border-radius: 50% !important;
                margin: 10px auto;
                box-shadow: 1px 1px 3px #00000029;
            }

            .ui.button.show-more {
                margin-top: 8px;
                padding: 8px;
            }
        }
    }
}