.WorkshopsRegistrationBlock {
  &.image {
    margin: 10px 0px;
    width: 100%;

    // Move button to the left
    .actions > .button--animator-access-button {
      margin-right: auto;
    }

    .Workshop {
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);

      &__Container {
        display: flex;

        &--Image {
          margin-right: 20px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          width: 300px;
          min-height: 200px;
          cursor: pointer;
        }

        &--Content {
          flex: 1;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 2em;
          }

          .date {
            margin-bottom: 1em;
          }

          .description {
            font-size: 1.2em;
          }

          .speakers {
            margin: 10px 0;
          }

          .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .see-more-button,
            .live-button,
            .event-status-button {
              margin-left: 5px;
            }

            .event-status-button--status-accept {
              color: white;
              background-color: #227606;
            }

            .event-status-button--status-pending {
              color: white;
              background-color: #002776;
            }

            .event-status-button--status-refuse {
              color: white;
              background-color: #760606;
            }

            .event-status-button__details {
              display: block;
              font-style: italic;
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .WorkshopsRegistrationBlock {
    &.image {
      .Workshop {
        &__Container {
          display: block;

          &--Image {
            margin-right: 0px;
            margin-bottom: 10px;
            width: 100%;
            max-width: 100%;
            padding-bottom: 56.25%;
          }

          &--Content {
            .actions {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
