.Tabs {
  display: flex;
  flex-direction: row;
  background: white;
  margin-top: 8px;

  &--bottom {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.77);
  }

  .Tabs--variant-round {
    margin: 0.5em;
    overflow: hidden;
    border-radius: 28px;
    padding: 0 24px;
  }

  .Tabs--variant-round-bottom {
    margin: 0.5em;
    margin-bottom: 0;
    overflow: hidden;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    padding: 0 12px;
  }

  .Tab {
    display: block;
    width: 0;
    flex: 1;
    text-align: center;
    padding: 0.5em;
    border-bottom: 3px solid transparent;
    color: #777;

    &.active-tab {
      background-color: var(--ac-color-primary);
      color: white;
    }

    i.icon {
      font-size: 1.25em;
    }

    &__Image {
      margin: 0 auto 4px;
    }

    &__Label {
      font-size: 10px;
      line-height: 1.2;
    }
  }
}
