.LineWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__line {
    height: 1px;
    background: rgba(128, 128, 128, 0.1);
    flex: 1;
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
}

.SlotGrid {
  .grid {
    margin-left: 0;
    margin-right: 0;

    .column {
      padding-left: 0 !important;
      padding-right: 0 !important;
      display: flex;
      flex-direction: row;
    }
  }
  .ui.button {
    margin-right: 0;

    &.disabled {
      opacity: 0.25 !important;
    }
  }

  .no-availability-message {
    position: fixed;
    margin: 70px 40px !important;
    z-index: 99 !important;
  }
}

.SlotDay {
  margin-bottom: 2em;
  min-width: 300px;
  max-width: 480px;
  margin: 0 auto;

  .slots--title {
    padding: 0.5em 0;
    font-weight: 400;
    font-size: 15px !important;
    color: #194798 !important; // TODO utiliser la primary colo
    border-bottom: 1px solid rgba(128, 128, 128, 0.25);
  }

  .content.active {
    margin-top: 0.5em;
  }

  .slots--content {
    // margin-bottom: 20px;
    // margin: 10px;
  }

  .slots--content.full {
    opacity: 0.7;
  }

  .remaining-appointment {
    color: grey;
    font-size: 12px;
    font-style: italic;
    margin-left: 10px;
  }

  .remaining-appointment.full {
    color: rgb(182, 21, 21);
    font-weight: bold;
  }

}