.SocialNetworkModal {

  &__header {
    text-align: center;
  }

  &__content {
    display: flex !important;
    flex-direction: column;
    text-align: center;
    padding-top: 0.4em !important;

    span {
      margin-bottom: 0.5em;
      font-size: 1.2em;
    }
  }

  &__sharingButtons {
    display: flex;
    justify-content: space-evenly;
  }

  &__shareButton {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 0.5em;
      font-size: 0.95em;
    }
  }

  &__copyInput input {
    cursor: pointer;
  }
}
