.NetworkingScreen {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;

  &--ChatContainer {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .NetworkingChat--Container{
    height: 56vh;
  }
}
