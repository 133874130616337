.DataItem {
    &__card {
        &.ui.card {
            cursor: initial !important;

            &.has-showModal {
                cursor: pointer;
            }

            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
            rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            transform: none !important;

            .content {
                display: flex;
                flex-direction: column;
            }
        }

        .fullDescription {
            flex: 1;
            margin-top: 10px;

            p {
                text-overflow: ellipsis;
                word-wrap: break-word;
                overflow: hidden;
                display: -webkit-box;
                -moz-line-clamp: 3;
                -webkit-line-clamp: 3;
                -moz-box-orient: vertical;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: black;
            }
        }

        .showMore.ui.button.basic {
            margin-top: 10px;
            text-align: right;
            font-weight: bold;
            border: none !important;
            box-shadow: none !important;
            padding-right: 0px;
        }
    }

}