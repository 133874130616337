.UserPreferencesModalBlock {
    &__preferences {
        .ui.card.preference {

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                .ui.button.follow {
                    box-shadow: none;

                    &.active {

                        background-color: white !important;
                    }
                }
            }


        }
    }
}