.MessagesPopup {
    position: relative;

    &.ui.popup {
        width: 270px;
        max-width: 270px;
        padding: 0px;

        .content {
            padding-top: 0px;
        }
    }

    &__header {
        &.header {
            margin-top: 0.5em !important;
            margin-bottom: 0.3em !important;
        }
    }

    &__button {
        &.ui.button {
            background-color: transparent;
            position: relative;
            z-index: 100;

            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }

    &__counter {
        background-color: red;
        color: white;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 6px;
        right: 10px;
        border-radius: 50%;
        line-height: 1;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
    }

    &__List {
        padding: 1em;
        max-height: 75vh;
        overflow-y: auto;

        &__subtitle {
            padding: 1em 0em;
        }

        &__Info {
            flex: 1;
        }

        &__Card {
            &.ui.card {
                margin: 0.5em 0em;
                box-shadow: none;
                border: none;

                &:hover {
                    transform: none !important;
                }
            }
        }

        &__Content {
            &.content {
                padding: 0.7em !important;
                align-items: center;
                display: flex;

                .hour {
                    font-size: 0.85em;
                    text-align: right;
                }

                .fullname.header {
                    margin-top: 0em;
                    margin-bottom: 0.2em;
                }

            }

        }

        &__Thumbnail {
            position: relative;
            margin-right: 1.2em;

            .unreadCount {
                padding: 0.2em;
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: red;
                color: white;
                position: absolute;
                top: -4px;
                right: -8px;
                z-index: 1;
            }

            .flag {
                height: 18px;
                width: 18px;
                position: absolute;
                bottom: 0px;
                right: -6px;
                z-index: 1;
                border: 1px solid white;
            }

            img.ui.image {
                border-radius: 50%;
            }

        }

        &__see-all-button {
            &.ui.button {
                font-weight: normal;
            }
        }

        &__Chat {
            i.icon.arrow.left {
                font-size: 1.2em;
                padding: 0em 1em;
            }

            .ui.feed.feed--chat {
                flex: 1;
                min-height: 50vh;
                height: 50vh;
                max-height: 50vh;
            }
        }
    }

}