.keyboard-toolbar {
  position: absolute;
  bottom: 0;
  transition: transform 250ms ease;
  z-index: 10000;

  &__desktop {
    margin-bottom: 15px;
  }
}
