.HexGrid {
    &__Row {
        // Disable point-events to prevent breaking click zones
        pointer-events: none;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;

        &:nth-child(1n + 2) {
            margin-top: -10.3%;
        }
        &:nth-child(even) > :first-child {
            margin-left: 16.666666667%;
        }
    }
}

.HexagonalItems {
    flex-wrap: wrap;
    display: flex;
    margin-top: 40px;
}

.HexagonalItem {
    display: inline-block;
    width: calc((100% - 20px) / 3);

    > svg {
        display: block;
    }

    // margin-bottom: 10px;
    &:not(:last-child) {
        margin-right: 10px;
    }

    &__Content {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 1.1;
    }
    &__Icon {
        > i {
            font-size: 2em;
        }
        > img {
            width: 2em;
            height: 2em;
            object-fit: contain;
        }
    }
}
