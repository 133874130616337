.actions-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 150px;
  border-radius: 10px !important;
  overflow: hidden;
  & button {
    width: 100%;
    border: none !important;
    background: transparent;
    text-align: start;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background 250ms;
    color: black !important;
    font-size: 16px;
    
    &:hover {
      background: rgba(120, 120, 120, 0.5);
    }
    & .actions-icons {
      font-size: 20px;
    }
  }
  & .ui.divider {
    margin: 0 !important;
  }
}
