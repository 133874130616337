$main-background: #f6f6f6;
$vertical-padding: 16px;
$horizontal-padding: 12px;

.social-wall-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;
  position: relative;
  background: $main-background;
}

.social-wall {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > .ui.secondary.pointing.menu {
    position: sticky;
    top: 0;
    background: white;
    margin-bottom: 0 !important;
  }
  & > .ui.secondary.pointing.menu .item {
    flex: 1;
    justify-content: center;
    margin: -2px 10px;
  }
  &__content {
    height: 100%;
    padding: $vertical-padding $horizontal-padding;
    overflow-y: scroll;
    &--empty {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.social-wall-button {
  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom));
  right: 10px;
  padding: 10px;
  box-shadow: none;
  border: 0;
  border-radius: 50px;
  overflow: hidden;
  z-index: var(--social-wall-button-index);
  &__high {
    bottom: calc(60px + env(safe-area-inset-bottom));
  }
}

.publication-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100%;
  align-items: center;
}

.social-wall-media-page {
  --gutter-column: 16px;
  --gutter-row: 12px;
  --columns: 2;
  // The content width you use on your website
  --content-width: 100vw;
  // This is the calculation for the row height.
  --row-size: calc(
    (var(--content-width) - (var(--gutter-row) * (var(--columns) - 1))) / var(--columns) * 0.75
  );
  grid-auto-rows: var(--row-size);
  display: grid;
  margin: auto;
  width: 100%;
  max-width: var(--content-width);
  grid-template-columns: repeat(var(--columns), 1fr);
  grid-column-gap: var(--gutter-column);
  grid-row-gap: var(--gutter-row);

  .media-card.potrait-mode {
    grid-row: span 2 / span 1;
  }
}

@media (min-width: 872px) {
  .social-wall-media-page {
    --columns: 3;
    --gutter-column: 30px;
    --gutter-row: 16px;
    --content-width: calc(872px - (2 * #{$vertical-padding}));
  }
}

@media (min-width: 1200px) {
  .social-wall-media-page {
    --columns: 4;
    --content-width: 920px;
  }
}

@media screen and (max-width: 767px) {
  .social-wall__content {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
