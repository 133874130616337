.BackButton__container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1em;
}

.BackButton {
  &--inverted {
    opacity: 0.9;
    color: white !important;

    .icon {
      cursor: pointer;
      background: white;
    }
  }

  &--inverted:hover {
    opacity: 1;
  }

  &--variant-link {
    background: transparent !important;
  }

  &--variant-square-icon {
    background: transparent !important;
  }
}