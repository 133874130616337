.agenda-stats {
    &.ui.segment {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    display: flex;

    &__statistics {
        width: 100%;
        margin: 0px !important;

        .ui.statistic {
            flex: 1;

            .label {
                margin: auto;
                text-align: center;
                max-width: 150px;
            }
        }


    }
}