.workshops--container--tabs {
  .ui.menu {
    background: white;
    border-radius: 4px;

    .tab--date {
      line-height: 1.2;
    }
  }

  .workshops--subgroup {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 767px) {
  .workshops--container--tabs {
    .ui.menu {
      // width: calc(100% + 2em) !important;
      // margin-left: -1em !important;
      // margin-right: -1em !important;

      position: sticky;
      top: 4px;
      z-index: 10;
    }
  }
}
