.RoomForm {
    .ui.form .field>label {
        font-size: 1em;
    }

    &__category {
        .header {
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    &__hotel,
    &__roommates,
    &__comment {
        margin-top: 1em !important;
    }

}