.IconGridItems--variant-circular {
  display: grid;
}

.IconGridItem--variant-circular {
  margin-bottom: 10px;

  .IconGridItem {
    &__Image {
      display: none;
    }

    &__Content {
      text-align: center;
    }

    &__Icon {
      margin: 0 auto;
      margin-bottom: 0.25em;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      font-size: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      > img {
        width: 50%;
        height: 50%;
        object-fit: contain;
      }

      > i.icon {
        color: white;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__Title {
      font-size: 1.1em;
      color: black;
      text-align: center;
      line-height: 1.15;

      &:after {
        // content: "";
        display: block;
        height: 2px;
        width: calc(100% - 8px);
        margin: 0 auto;
      }
    }
  }
}
