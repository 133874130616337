.page--profile {
    .button.ui {
        border-radius: 20px;
    }
    .ui.card.card--shadow {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px !important;
        padding: 20px;
        margin-bottom: 20px;

        >.border {
            position: absolute;
            height: 6px;
            top: 0px;
            left: 0px;
            right: 0px;
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px;
        }
    }

    .card {
        &--title {
            margin-top: 0px;
            font-size: 2em;
            text-align: left;

        }

        &--subtitle {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            margin-bottom: 10px;

            .title {
                margin: 0px;
                font-size: 1.2em;
                padding-right: 10px;
            }

            .divider {
                height: 1px;
                flex: 1;
                margin-top: 3px;
            }
        }

        &--item {
            &__name {
                padding-left: 25px;
            }

            &__value {
                font-weight: bold;
            }
        }

        &--labels {
            .title {
                display: flex;
                align-items: center;

                p {
                    margin: 0px;
                    margin-top: 4px;
                    padding: 0px 12px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

        }
        &--qrcode {
            max-width: 15em;
            align-self: center;
            -ms-align-self: center;
        }

    }
}

