.DataItems {
    &__group {
        margin-bottom: 20px;

        &--variant {
            &-accordion {
                .Collapsible {
                    background-color: #fff;
                }

                .Collapsible__contentInner {
                    padding: 20px;
                    border: 1px solid #ddd;
                    border-top: 0;
                }

                .Collapsible__trigger {
                    cursor: pointer;
                    color: white;
                    display: block;
                    text-decoration: none;
                    position: relative;
                    border-bottom: none;
                    padding: 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .DataItems {
        &__group {
            &--variant {
                &-accordion {
                    .Collapsible__contentInner {
                        padding: 0px;
                    }
                }
            }
        }
    }
}