.UserRankingInOwnTeam {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;

    .team.ui.header {
        margin-bottom: 0px;
    }

    .userCount {
        color: #B7B3B3;
        font-size: 0.8em;
    }
}