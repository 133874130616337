.files-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__item {
        width: 50%;

        &:nth-child(2n) {
            padding-left: 20px !important;
        }
    }
}

@media (max-width: 480px) {
    .files-list {
        &__item {
            width: 100%;

            &:nth-child(2n) {
                padding-left: 0px !important;
            }
        }
    }
}