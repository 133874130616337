@import "../../../../scss/helpers/positions";

.HexagonalItem {
    display: inline-block;
    position: relative;
    // align-items: center;
    // justify-content: center;

    &__Wrapper {
        @include pos-absolute(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__Content {
        flex: 1;
    }

    &__Icon {
        color: white;
        margin-bottom: 4px;
    }

    &__Title {
        color: white;
    }
}
