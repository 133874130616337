.flipClock {
  display: flex;
  justify-content: center;
  margin: auto;

  .label {
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
  }

  .flip-countdown {
    &.tick {
      font-size: 3em;
    }

    &__container {
      white-space: nowrap;
      margin: 4px !important;
    }
  }

}

@media (max-width: 480px) {
  .flipClock {
    .flip-countdown.tick {
      font-size: 2.3em;
    }
  }
}