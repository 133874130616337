.u-singleline {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-mobile-only {
  display: none !important;

  @media (max-width: 480px) {
    display: block !important;
  }
}

.u-mobile-hide {
  display: block !important;

  @media (max-width: 480px) {
    display: none !important;
  }
}
