.upload-toolbar-container {
  display: flex;
  flex: 1;
  justify-content: center;
}

.upload-toolbar {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;

  & span {
    color: #a1a1a1;
  }

  & .desktop-text {
    display: none;
  }

  &--bordered {
    background-color: transparent;
    border: 1px solid #ced0d4;
    border-radius: 15px;

    & .desktop-text {
      color: black;
      padding: 15px;
      text-align: center;
      display: inherit;
    }
  }
  & .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 100%;
  }
  & .upload-toolbar-items-divider {
    height: 60%;
    border: 1px solid #cdcbcb;
  }
}

.upload-toolbar-item {
  flex: 1;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  & input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
  }

  &__label {
    flex: 1;
    display: flex;
    padding: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
  }

  span {
    margin-right: 8px;
  }
}
