.ItemInfo {
  .field {
    &--type-tag {
      font-size: 0.8em;
      padding: 0.1em 0.5em;
      font-weight: normal;
      border-radius: 1em;
      color: rgba(255, 255, 255, 0.9);
      white-space: nowrap;
    }
  }
}
