.menu--filter {
  .item>.count {
    float: right;
  }


  .item.FavoriteFilter__Item {
    padding-top: 0.2em !important;
  }

  .item.reset-all {
    text-align: right;
    padding-top: 0px;
    padding-bottom: 0px;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.9em;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.menu-filter {
  &--tag {
    .tags__item {
      &.ui.button {
        margin-bottom: 2px;
      }
    }
  }


}

.ui.menu>.item>.ui.text.menu {
  margin-top: 0;
  margin-bottom: 0;
}