.NetworkingUserItem {
  flex-direction: row !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  position: relative !important;

  .flag {
    border: 1px solid white;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 55px;
    bottom: 8px;
  }

  &--Avatar {
    // flex: 0 1 5rem !important;
    align-self: flex-start;

    &.ui.tiny.image {
      width: 70px !important;
    }
  }

  &--Content {
    padding-top: 0 !important;
    padding-left: 1rem !important;

    .NetworkingParticipantList--Message {
      float: right;
    }

    .header {
      margin-top: 4px !important;
    }

    .ui.label {
      margin-top: -1px;
      margin-left: 4px;
      padding: 4px 8px;
    }
  }

  &--Meta {
    &--Field {
      // display: inline-block;
      padding-right: 0.2rem;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .NetworkingUserItem .ui.items:not(.unstackable) > .item > .image,
  .ui.items:not(.unstackable) > .item > .image > img {
    width: 70px !important;
  }
}
