.UserAvatarImageUploader {
    margin-bottom: 10px;

    &__Dropzone,
    &__Thumbnail {
        &--enableChange {
            cursor: pointer;
        }

        position: relative;
        margin: auto;
        text-align: center;
        height: 100px;
        width: 100px;
        border: 2px solid white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(128, 128, 128, 0.1);

        img.userAvatar {
            border-radius: 50%;
        }

        .placeholder {
            padding: 4px;
            font-size: 10px;
            line-height: 1;
        }

        & i.icon.camera,
        & .picto {
            z-index: 1;
            font-size: 1.5em;
            position: absolute;
            bottom: 0%;
            right: 10%;
            background-color: white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
        }
    }

    .ui.progress {
        margin: 0px;
        width: 90%;
        text-align: center;
    }
}
