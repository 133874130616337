.modal--user-profile .content > .ui.image {
  margin: 0 auto;
}

.ui.form {
  .ui.table--profile {
    .field {
      > label {
        display: none;
      }
    }
  }
}

.ui.modal.modal--inverted {
  > .content {
    background: rgb(14, 14, 31);

    .ui.card {
      background: transparent;
    }
  }
}

.UserProfile {
  &__qrcode {
    text-align: center;

    @media (max-width: 767px) {
      &.ui.segment {
        margin: 0 auto !important;
        max-width: 400px;
      }
    }
  }

  &__column {
    &--avatar {
      .ui.segment {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .ui.header.fullname {
        margin-top: 12px;
        text-align: center;
      }
    }

    .button-group--files {
      margin-left: auto;
    }
  }
}

@media (max-width: 767px) {
  .ui.grid.profile--block .user-profile.row {
    display: flex;
    flex-direction: column;
  }

  .UserProfile {
    &__column {
      &--qrcode {
        order: 1;
      }

      &--info {
        order: 2;
      }

      &--avatar {
        order: 0;
      }
    }
  }
}
