.poster {
    &__countdown {
        position: absolute;
        bottom: 0px;
        padding: 1em 1em 0.5em;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
        font-weight: normal;
        right: 0px;
        left: 0px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 1.4em;
    }
}