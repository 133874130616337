.WorkshopsRegistrationBlock {
    &.grid {

        .Workshop {
            &.ui.card {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                text-align: left;
                .image {
                    z-index: 1;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    height: 250px,
                }

                .description {
                    margin-top: 10px;
                }

                .actions {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .see-more-button {
                        margin-left: 5px;
                    }
                }
            }

        }
    }
}