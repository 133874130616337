.ui.list>.item.item--table {

  .unread {
    float: right;
    margin-top: -3px;
    margin-bottom: -3px;
    right: 0px;
    margin-left: 4px;
  }

  .hour {
    font-size: 0.8em;
    text-align: right;
  }

  .image-container {
    position: relative;

    .flag {
      border: 1px solid white;
      width: 20px;
      height: 20px;
      position: absolute;
      right: -2px;
      top: 30px;
    }
  }

  .content {

    &--action {
      width: 40px;
      cursor: pointer;
      opacity: 0.7;

      .icon {
        color: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        .icon {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
}