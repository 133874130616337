.UserStatus {
    display: flex;
    align-items: center;
    margin-top: 8px;

    &__icon {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        line-height: 1;

        i {
            font-size: 10px;
            margin: 0px;
            height: auto !important;
        }
    }

    &__fullname {
        .organizer {
            font-size: 11px;
            line-height: 11px;
            color: #555;
        }
    }
}