$white-smoke-color: rgb(224, 225, 226);
.ac-label {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  background-color: $white-smoke-color;
  border-radius: 10px;
  padding: 5px;
  height: 24px;

  &--flat {
    background-color: transparent;
  }

  &__value {
    font-size: 11px;
    font-weight: bold;
  }
}
