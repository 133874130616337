.media-card {
  position: relative;
  box-shadow: 0px 2px 2px rgba(184, 184, 184, 0.5);
  border-radius: 12px;
  user-select: none;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  .video-play-icon {
    // To keep the "zoom" effect even with the thumbnail
    &:hover + .media-card__image {
      transform: scale(1);
    }
  }

  &__image {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.02);
    transition: transform 300ms;
    &:hover {
      transform: scale(1);
    }
  }

  &__interactions {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgb(31, 30, 30, 0.3) 100%);
    padding: 10px;
    width: 100%;
    pointer-events: none;
    &:hover + .media-card__image {
      transform: scale(1);
    }
  }
}
