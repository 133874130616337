.RestrictOpeningTimesBlock {
  &__Content {
    white-space: pre-line;
  }

  &__OpenTimes {
    font-weight: bold;
  }

  &__Image {
    img.ui.image {
      display: inline-block;
    }
  }
}
