.popup-trigger {
  padding: 4px 8px !important;
  background: #f2f2f2 !important;

  &.ui.button {
    border-radius: 20px;
    color: unset !important;
    margin: 0 !important;
    font-weight: normal;
    padding: 6px;
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }

  & img {
    height: 12px;
    width: 30px;
  }
}

.emote-picker {
  &__popup {
    padding: 0 !important;
    border-radius: 8px !important;
    border: none !important;
    .EmojiPickerReact {
      --epr-emoji-size: 22px !important;
    }
  }

  &__panel {
    .EmojiPickerReact {
      width: 100% !important;
      height: 100% !important;
      border: none !important;
    }
  }

  &__add-reaction-icon {
    padding: 1px 4px 0 4px;
  }

  &__wrapper {
    width: 100%;
    .EmojiPickerReact {
      min-width: 300px !important;
    }
  }
}
