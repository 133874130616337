.user-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  &--dark {
    & .title {
      color: black !important;
    }
    & .description {
      color: grey !important;
    }
  }

  & img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }

  &__name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .title {
      color: white;
      font-weight: bold;
      font-size: 14px;
      line-height: normal !important;
      flex: 1;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .description {
      color: white;
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
