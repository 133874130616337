$footer-height: 50px;

.media-image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: filter 250ms;
  &__dark {
    filter: brightness(0.5);
  }

  & .background-image {
    position: absolute;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(60px);
    z-index: 0;
  }
  & .main-image {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  & video {
    width: 100%;
    object-fit: contain;
    height: 100%;
    padding-bottom: calc($footer-height + 10px);
    &:fullscreen {
      padding-bottom: 0px !important;
    }
  }
}
.media-footer {
  z-index: 1;
  position: absolute;
  background: linear-gradient(0, rgba(80, 80, 80, 0.5), transparent);
  padding: 0px 20px 0px 10px;
  width: 100%;
  padding-bottom: $footer-height;
  bottom: 0;

  &__emotes-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &--end {
      justify-content: flex-end !important;
    }
  }
  & .media-text {
    line-height: 20px;
    color: white;
  }
}
