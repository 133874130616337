@media (max-width: 480px) {
  .workshop-card-3dx {
    width: 100% !important;
    padding-right: 10px !important;
    margin-bottom: 20px !important;
  }
}

.workshop-card-3dx {
  display: flex;
  align-items: top;

  width: 280px;
  margin: 4px;

  &--can-unregister {
    .close.icon {
      position: relative;
      bottom: 149px;
      left: 125px;
      height: 0;
      color: #fff;
      z-index: 10;
    }

    .close.icon:hover {
      color: rgba(0, 0, 0, 0.55);
    }
  }

  &>a.ui.card:hover {
    box-shadow: none;
    transition: none;
    border: none;
  }

  &>.ui.card {
    border-radius: 15px !important;
    box-shadow: none;
    transition: none;
    display: flex;
    width: 100%;
    padding-bottom: 0.5em;

    .workshop-card-3dx {
      &__schedule {
        padding-top: 6px;
        padding-bottom: 8px;

        .description>.datetime {
          display: flex;
          flex-direction: column;

          .date {
            margin-bottom: 2px;
          }
        }
      }

      &__banner {
        padding: 0px;
        border-radius: 14px 14px 0px 0px !important;
        overflow: hidden;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .headers {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          padding: 14px;
        }

        .header.title {
          color: #fff;
          font-weight: bold;
        }

        .subtitle {
          color: white;
        }

        img {
          box-shadow: none;
          transition: none;
        }
      }

      &__buttons {
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;

        &.content:after {
          display: none;
        }
      }

      &__label {
        height: 22px;
        border-radius: 8px;
        line-height: 8px;
        white-space: nowrap;

        &--registered {
          justify-self: flex-end;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      &__action-buttons {
        display: flex;
        flex-direction: column;
      }

      &__button {
        height: 15px;
        border-radius: 8px;
        line-height: 0px;
        font-size: 12px;
        white-space: nowrap;
        padding-left: 10px;
        padding-right: 10px;

        &--accessLive,
        &--addToSchedule {
          color: #0c4a7b !important;
          background-color: #fff !important;
          border: 2px solid #0c4a7b;
          font-weight: bold;
        }

        &--removeFromSchedule {
          color: #fff !important;
          background-color: rgb(136, 55, 55) !important;
          font-weight: bold;
          margin-top: 4px;
        }
      }

      &__content {

        &--speakers,
        &--languages,
        &--description {
          padding-top: 0px;
          padding-bottom: 3px;

          .meta {
            color: #484848;
            font-size: 1em;
          }

          .description {
            font-size: 0.95em;
            margin-top: 0px;
            font-weight: bold;
          }
        }
      }

      &__hr {
        padding: 0.5em 1em;
      }

      &__hr hr {
        border-bottom: 0;
        border-color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .ui.card>.content {
    flex-grow: 0;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}