.ui.card.card--sponsor {
  max-width: 270px;

  .exhibitor.favorite {
    text-align: right;
    width: 100%;
    padding-right: 4px;
    padding-top: 4px;
  }

  .content.logo {
    border-top: 0px;
  }

  img.logo {
    display: block;
    width: 100%;
    height: 70px;
    border-top: 0px;
    object-fit: contain;
  }

  .trophy--platinum {
    background-color: #2185d0;
    border-color: #2185d0;
    color: #fff;
  }

  .trophy--gold {
    background-color: #fbbd08;
    border-color: #fbbd08;
    color: #fff;
  }

  .trophy--silver {
    background-color: #767676;
    border-color: #767676;
    color: #fff;
  }
}

@media (max-width: 767px) {
  .ui.card.card--sponsor {
    max-width: 100%;
    width: 100%;
  }
}
