@import './actions.scss';
@import './_mixins.scss';

.Contacts--variant-visit-card {
  @include grid(2, 0px);
}

.Contact--variant-visit-card {
  aspect-ratio: 2 / 1;
  background: var(--ac-color-white-3);
  border-radius: 2px;
  overflow: hidden;

  .Contact {
    &__Wrapper {
      height: 100%;
      position: relative;
      display: flex;
      padding: 1em;
      flex-direction: column;
      justify-content: center;
      padding-left: calc(50% + 1em);
    }
    &__Profile {
      padding: 1em;
    }
    &__Thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;

      // Inject arrow
      &::after {
        content: ' ';
        display: block;
        width: 2em;
        height: 2em;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      }
    }
  }

  // First 2
  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
  }

  // Next 2
  &:nth-child(4n + 3),
  &:nth-child(4n + 4) {
    .Contact {
      &__Wrapper {
        padding-left: 1em;
        padding-right: calc(50% + 1em);
      }
      &__Thumbnail {
        left: initial;
        right: 0;
        &::after {
          right: initial;
          left: 0;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  .ItemInfos {
    padding: 1em 1em 1em 0;
    text-align: left;
    margin: 0 auto;
  }

  .ItemInfo {
    &--title {
      font-size: 1.5em;
      line-height: 1.1;
      margin-bottom: 0.5em;
    }

    &--subtitle {
      font-size: 0.9em;
    }
  }

  .Actions {
    padding: 1em 0;
    padding-top: 0;
  }

  @include Actions--icon-text;
  @include Actions--hover-opacity;
}
