.SearchBlock {
    display: flex;
    align-items: center;
    margin: 10px 0px;

    &__input {
        min-width: 300px;

        ::placeholder {
            font-style: italic;
        }
    }
}

@media (max-width: 480px) {
    .SearchBlock {
        &__input {
            width: 100%;
        }
    }
}