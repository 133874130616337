.WorkshopsRegistrationBlock {
  &.light {
    margin: 10px 0px;
    width: 100%;

    .Workshop {
      color: black;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);

      &__Container {
        display: flex;

        &--Main {
          flex: 1;
        }

        &--Sidebar {
          width: 200px;
          max-width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 20px;

          .actions {
            text-align: center;

            .ui.button {
              margin-left: 0px;
              margin-right: 0px;
            }

            .see-more-button {
              margin-top: 10px;
              width: 150px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .WorkshopsRegistrationBlock {
    &.light {
      .Workshop {
        &__Container {
          display: block;

          &--Sidebar {
            width: 100%;
            max-width: 100%;
            margin-left: 0px;
          }
        }
      }
    }
  }
}
