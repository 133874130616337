.AddressBlock {
    overflow: hidden;

    .ui.segment {
        padding: 0px;
    }

    &__infos {
        padding: 1em;

        .ui.header.name {
            margin-bottom: 0px;
        }

        .address {
            margin-top: 4px;
            margin-bottom: 4px;
            white-space: pre-line;
        }
    }
}
