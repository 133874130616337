.file-thumbnail-container {
  position: relative;
  margin-top: 5px;

  & button {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 100%;
    border: none;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .video-thumbnail,
  .image-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;

    &__vertical {
      img, video {
        height: 400px;
        width: auto;
      }
    }
    &__horizontal {
      img, video {
        width: 100%;
        max-width: 500px;
        height: auto;
      }
    }
  }
  
  .video-thumbnail{
    cursor: pointer;
  }
}
