.page--iframe {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .page--cms-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    > .content {
      flex: 1;

      > .ui.container {
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .ui.container {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
