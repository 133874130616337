.UserInfoTab {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0px;
  &__fields {
    flex: 1;
    overflow-y: auto;
  }
  &__field {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 0.8em;
    .label {
      color: #969696;
      font-size: 0.8em;
    }

    .value {
      color: black;
      font-size: 1em;
    }
  }
  &__edit {
    &.ui.button {
      margin: 1em 0em;
      border-radius: 20px;
      width: 100%;
    }
  }
}
