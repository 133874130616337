.WorkshopSessionRegistationButton {
    &__AlreadyRegistered {
        .already-registered-label {
            text-align: right;
            color: red;
        }

        .datetime {
            span {
                display: block;

                &.time {
                    margin-bottom: 10px;
                }
            }
        }

        .ui.button {

            &.unregister-button {
                background: none;
                font-weight: normal;
                color: #999;
                font-size: 13px;
                width: 100%;
            }

            &.access-button {
                margin-top: 10px;
            }

        }
    }

    &__Unregistered {
        .interested-by-workshop-label {
            text-align: right;
        }

        .register-button {
            border-radius: 20px;
            width: 100%;
            text-transform: uppercase;
        }
    }

}