.select-entity--dropdown {

  .divider.text {
    display: flex;
  }

  .menu.transition {
    .item {
      display: flex;
    }
  }
}