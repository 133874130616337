.workshop--vod {
    display: flex;
    align-items: top;
    width: 270px;
    margin-right: 12px;

    &>.ui.card {
        margin-bottom: 10px;
        width: 100%;

        .ui.tiny.progress .bar {
            min-width: 0em;
        }

        &>.card__image {
            padding: 0px;
            border-radius: 0px;
            position: relative;

            .ellipsis {
                position: absolute;
                right: 0px;
                top: 8px;
                z-index: 4;
                color: white;
                font-size: 20px;
                background-color: rgba(0, 0, 0, 0.4);
                padding: 2px 0px;
                width: 14px;
                height: max-content;
            }

            .overlay {
                z-index: 3;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                top: 0px;
                right: 0px;
                left: 0px;
                bottom: 0px;

                &.hasActions {
                    background-color: rgba(0, 0, 0, 0.4);
                }

                .actions {
                    .action.ui.button {
                        padding-right: 2.5em !important;
                        padding-left: 1em !important;
                    }
                }
            }

            .image-with-shadow {
                border-radius: 0px;
                z-index: 1;
                background-size: cover;
                background-position: center;
                height: 156px;
                padding-bottom: 0px;
            }

            .duration {
                position: absolute;
                bottom: 8px;
                right: 8px;
                z-index: 4;
                background-color: rgba(0, 0, 0, 0.6);
                padding: 2px 4px;
                color: white;
            }

        }

        .logos {
            margin-bottom: 8px;

            img {
                margin-right: 8px;

            }
        }

        .tags {
            margin-top: 10px;
        }
    }

    .ui.card>.content {
        flex-grow: 0;
    }

    .workshop--category {
        line-height: 1.5;
    }

    &.hidden {
        opacity: 0.5;
    }
}

@media (max-width: 480px) {
    .workshop--vod {
        width: 100% !important;
    }
}