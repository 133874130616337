.TypologyBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: 10px;
  }
}
