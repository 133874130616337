.DataModal {
    &__extra {
        margin-top: 1em;
        display: grid;
        grid-template: 1fr 1fr / 1fr 1fr;

        .extraField {
            margin: auto;
            padding-right: 0.7em;
            padding-bottom: 0.5em;
        }
    }
}