@import './_mixins.scss';

.Contacts--variant-contact-card {
  @include grid(4, 1em);
}

.Contact--variant-contact-card {
  background: var(--ac-color-white-3);
  box-shadow: 0px 0px 10px 0px var(--ac-color-white-2);
  border-radius: 2px;
  overflow: hidden;
  // aspect-ratio: 1 / 1;

  .Contact {
    &__Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__Profile {
      display: flex;
      flex-direction: column;
    }
    &__Thumbnail {
      padding: 1em;
      > img {
        width: 4.5em;
        border-radius: 50%;
      }
    }
  }

  .ItemInfos {
    padding: 0 1em;
  }
  .ItemInfo {
    &--title {
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.25em;
    }
    &--subtitle {
      font-size: 1em;
      opacity: 0.6;
    }
  }

  .Actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    padding-top: 0;
  }
  .Action {
    width: 100%;
    display: flex;
    align-items: center;
    // padding: 0.25em 1em;
    height: 100%;

    border-radius: 50%;
    margin-top: 1em;
    width: 4.5em;
    height: 4.5em;
    line-height: 4.5em;
    overflow: hidden;

    &:hover {
      opacity: 0.8;
    }

    &__Icon {
      color: #fff;
      width: 100%;
      & i {
        width: 100%;
        margin: auto;
      }
    }
    &__Label,
    &__Value {
      display: none;
    }
  }
}
