.Chat--Feed {
  .Chat--Message {
    &.event--type-appointment-update {
      background: rgb(128, 128, 128, 0.25);
      border-radius: 4px;
      margin: 4px auto;
      padding: 4px 8px;
      font-style: italic;
      font-size: 13px;
    }

    &.event--type-visio-request {
      > .content {
        padding: 0;
        background: 0;
      }
    }

    .label {
      position: relative;

      .flag {
        border: 1px solid white;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -2px;
        top: 30px;
      }
    }
  }

  & .Chat--Feed__TextContent {
    display: flex;
    align-items: flex-start;

    & p {
      margin: 0 0 0.5rem;

      > span {
        white-space: pre-line;
      }
    }

    & .Chat--Feed__DeleteButton {
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 0;
      align-self: center;
      opacity: 0;
    }

    &:hover .Chat--Feed__DeleteButton {
      opacity: 1;
    }
  }
}
