.connexion-test-block {
    &.ui.card {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    .header {
        padding: 10px;
        font-weight: bold;
        text-align: center;
    }

    .description.button {
        margin-top: 10px;
        text-align: center;

        .ui.button {
            border-radius: 20px;
            max-width: max-content;
        }
    }

}