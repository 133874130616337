.workshop--clickable--card {

    display: flex;
    align-items: top;
    width: 270px;
    margin-right: 12px;
    background-color: transparent !important;


    &>.ui.card {
        margin-bottom: 10px;
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

        .card {
            &__image {
                position: relative;
                min-height: 150px;
                background-size: cover;
                background-position: center;
                border-bottom-left-radius: 0px !important;
                border-bottom-right-radius: 0px !important;
            }

            &__duration {
                z-index: 1000;
                padding: 2px 8px;
                background-color: rgba(0, 0, 0, 0.45);
                color: white;
                font-size: 11px;
                position: absolute;
                bottom: 0px;
                right: 0px;
            }
        }


    }

    .ui.card>.content {
        flex-grow: 0;
    }
}