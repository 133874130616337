.IconGridItem {
  cursor: pointer;
  display: block;

  &__Image {
    > img {
      object-fit: cover;
    }

    &--fit-cover {
      > img {
        object-fit: cover;
      }
    }
    &--fit-contain {
      > img {
        object-fit: contain;
      }
    }
  }
}
