.ui.fluid.card.CTATile.CTATile--variant-card {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .achievement-badge--container {
    margin-right: -0.6em;

    .ui.image.achievement-badge {
      height: 1.5em;
    }
  }
}
