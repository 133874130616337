.ui.menu.DaysContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .day.item {
        padding: 10px 1.5em;
        font-size: 1.2em;
        border-radius: 20px;
        background-color: white;
        color: black;

        &:hover {
            background-color: white;
            font-weight: bold;
        }

        &.selected {
            font-weight: bold;
        }
    }

}