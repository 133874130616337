$gold: #f3c845;
$silver: #c6d1dc;
$bronze: #dab187;

.CmsPageLayout {
  &--theme {
    &-dark {
      .RankingByTeam {
        &__Team {
          background-color: #1e2237;

          .rank {
            color: white;
            border-color: white;
          }

          .infos {
            color: white;

            .userCount {
              color: #b7b3b3;
            }
          }

          .score {
            color: white;
          }
        }
      }
    }

    &-light {
      .RankingByTeam {
        &__Team {
          background-color: #eaeaea;

          .rank {
            color: black;
            border-color: black;
          }

          .infos {
            color: black;

            .userCount {
              color: #777;
            }
          }

          .score {
            color: black;
          }
        }
      }
    }
  }
}

.RankingByTeam {
  flex: 1;
  height: 0px;
  overflow-y: auto;

  &__Team {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.8em;
    border-radius: 12px;
    padding: 0.5em 1em;

    @media (max-width: 767px) {
      border-radius: 20px;
    }

    .rank {
      width: 30px;
      height: 30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border: 2px solid white;
      border-radius: 50%;
      margin-right: 1.5em;
    }

    &:first-child {
      .rank {
        background-color: $gold;
        border-color: $gold;
      }
    }

    &:nth-child(2) {
      .rank {
        background-color: $silver;
        border-color: $silver;
      }
    }

    &:nth-child(3) {
      .rank {
        background-color: $bronze;
        border-color: $bronze;
      }
    }

    .infos {
      flex: 1;

      .team {
        font-weight: bold;
      }

      .userCount {
        color: #b7b3b3;
        font-size: 0.8em;
      }
    }

    .score {
      color: white;
      font-weight: bold;
    }
  }
}
