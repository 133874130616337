.NetworkingParticipantList {
  &--LoadMore {
    width: 100%;
  }
}

.NetworkingParticipantList--Message {
  display: inline-block;
  padding: 0.5rem !important;
  margin: 0.3rem !important;
  line-height: 1 !important;
  font-size: 1rem !important;
}