.WebinarLiveBlock {
    &__content {
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        margin-top: 8px;
    }

    .live {
        padding: 0px 8px;
        background-color: #FF0000;
        color: white;
    }
}