.publication-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & span {
    font-weight: bold;
    font-size: 16px;
  }
  & .close-button {
    background-color: transparent !important;
    border: 0 !important;
    position: relative !important;

    &__desktop {
      border: 0;
      background-color: #e4e6eb;
      border-radius: 50%;

      &:after {
        content: '\2715';
        font-size: 18px;
        color: #5f676f;
      }
    }

    &:after {
      content: '\2715';
      font-size: 18px;
      color: black !important;
    }
  }

  & .publish-button {
    background-color: var(--ac-color-primary);
    border-radius: 15px;
    padding: 5px 10px;
    color: white;
    border: none;
  }

  & button {
    transition: all 300ms;
    cursor: pointer;
    font-size: 14px;
  }

  & button:disabled {
    color: black;
    background-color: #d2d2d2;
  }

  & button:hover:enabled {
    filter: brightness(130%);
  }

  & div {
    display: flex;
    flex-direction: row;
    flex: 1;

    &.close-button-container {
      justify-content: flex-start;
    }
    &.publish-button-container {
      justify-content: flex-end;
    }
  }
}
