.CmsPageLayout {
  &--theme {
    &-dark {
      .UserRanking {
        color: white;
        background-color: #1e2237;

        .company {
          color: #b7b3b3;
        }

        .stats {
          background-color: #151729;

          > div.stat {
            border-right: 1px solid #252a40;

            .label {
              color: #b7b3b3;
            }
          }
        }
      }
    }

    &-light {
      .UserRanking {
        color: black;
        background-color: #eaeaea;

        .company {
          color: #777;
        }

        .stats {
          background-color: #dedede;

          > div.stat {
            border-right: 1px solid #aeaeae;

            .label {
              color: #777;
            }
          }
        }
      }
    }
  }
}

.UserRanking {
  position: relative;
  border-radius: 12px;
  text-align: center;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0px;

  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
    background: white;
    padding: 0.4em 0.8em;
    border-radius: 8px;

    .label,
    .score {
      font-weight: bold;
      color: var(--ac-color-secondary);
    }
    .ProgressBar__wrapper {
      flex: 1;
      margin-top: 0em;
      .ProgressBar__progress-bar {
        flex: 1;
        margin: 0em 1em;
        width: calc(100% - 2em);
        background-color: var(--ac-color-secondary-20);
        .ProgressBar__progress-bar-fill {
          height: 1em;
          background-color: var(--ac-color-secondary);
        }
      }
    }
  }

  @media (max-width: 767px) {
    border-radius: 40px 40px 0px 0px;
  }

  img.thumbnail {
    margin: auto;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
    height: 90px;
    width: 90px;
    object-fit: cover;
  }

  .fullname {
    font-weight: bold;
    padding-top: 60px;
    font-size: 1.2em;

    .lastName {
      text-transform: uppercase;
    }
  }

  .company {
    margin-top: 0.5em;
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 1em 0em;
    margin: 1em;

    > div.stat {
      flex: 1;
      padding: 0em 1em;

      &:last-child {
        border-right: none;
      }

      .label {
        margin-bottom: 0.2em;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .badges {
    flex: 1;
    height: 0px;
    overflow-y: auto;

    .category-badges {
      &.ui.grid {
        margin: 0px !important;

        .badge {
          text-align: center;

          img.image {
            margin: auto;
          }

          .title {
            margin-top: 0.4em;
            font-weight: bold;
            text-align: center;
            font-size: 0.9em;
            line-height: 1.1;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ui.grid.category-badges {
    display: flex;
  }
}
