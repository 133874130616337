.avatars {
  margin-top: 8px;
  margin-bottom: 4px;

  img {
    margin-right: 10px;
  }
}

.speaker {
  &__modal {
    .ui.card.card--speaker {
      width: 100%;
      margin-top: 100px !important;
      border: none;
      box-shadow: none;
    }
  }
}

.AvatarsBlock {
  &--square {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: 0px !important;


    &__avatar {
      max-width: 150px;
      min-width: 150px;
      margin-bottom: 10px;
      margin-right: 16px;

      &.clickable {
        cursor: pointer;
      }

      .email.bubble {
        position: absolute;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
        bottom: -13px;

        i {
          height: 1.3em;
          margin: 0px;
          font-size: 1.2em;
        }
      }

      .flag {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 20px;
        bottom: 0px;
        border: 1px solid white;
      }

      img.thumbnail {
        margin: auto;
      }

      h4.ui.header.fullname {
        margin-top: 16px;
        margin-bottom: 0px;
      }
    }

  }
}