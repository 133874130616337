.AddMeetingRequest {
    &.ui.modal {
        max-width: 500px;
    }

    .ui.form {
        .fields {
            .label {
                min-width: 72px;
                width: 72px;
                padding-top: 0.5em;
                font-weight: bold;

                &.required:after {
                    content: " *";
                    color: red;
                }
            }
        }
    }
}
