.ui.modal {
  &.fullscreen--fixed {
    position: fixed;
    left: 1rem;
    right: 1rem;
    top: 1rem;
    bottom: 1rem;
    width: auto;
    height: auto;
    margin: 0 !important;
    display: flex !important;
    flex-direction: column;

    .header {
      padding: 1rem;
      font-size: 1.1rem;
    }

    .content {
      flex: 1;
    }
  }
}
