.SuggestedWorkshops {
    &__Item {
        .date {
            margin-top: 0em !important;
        }
    }

    &--variant {
        &-card {
            .duration {
                position: absolute;
                bottom: 0px;
                right: 0px;
                color: white;
                line-height: 1;
                background-color: rgba(255, 255, 255, 0.7);
                padding: 0.2em 0.4em;
                font-size: 0.9em;
            }
        }
    }
}