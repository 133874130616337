.sso-button--social {
    border-radius: 0.4em !important;
    margin-bottom: 0.5em !important;
    font-size: 1em !important;
    width: calc(100% - 7.5em) !important;
}

@media (max-width: 480px) {
    .sso-button--social {
        width: calc(100% - 4.5em) !important;
    }
}
