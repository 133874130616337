.footer {
    text-align: center;
    margin: auto;
    margin-top: 16px;
    width: 100%;

    &--container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > * {
            margin: 0 2rem;
        }
    }

    &__link {
        cursor: pointer;
    }

    &--container {
        padding: 10px;
    }

    &--text {
        flex: 1;
        color: white;
        padding: 10px 30px;
    }

    &--logo {
        margin: auto;
    }

    &--socialmedia {
        i {
            color: white;
        }
    }

    &--cookie-modal {
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 370px !important;
    }
}

.ui.dimmer--cookie-modal {
    // background-color: rgba(255, 255, 255, 0.3);
    background-color: unset;
    position: fixed !important;
    right: 10px !important;
    bottom: 10px !important;
    height: unset !important;
    top: unset !important;
    left: unset !important;
    width: unset !important;
}

@media (max-width: 480px) {
    .footer {
        position: relative;

        &--container {
            display: block;

            > * {
                margin: 1rem 0;
            }
        }

        &--text {
            font-size: 12px !important;
        }
    }
}
