.secondary-menu__item--type-search {
  .SearchRenderer {
    // max-width: 129px;
    width: 129px;
    transition: width var(--ac-transition-normal) ease-in-out;

    &.focus,
    &--hasValue {
      transition: width var(--ac-transition-slow) ease-in-out;
      width: 250px;
      // max-width: 250px;
    }

    .item.SearchResultItem {
      background-color: transparent !important;
      padding: 0.5rem;

      .content {
        padding-left: 10px;
      }

      &--collection-speakers .ui.image {
        border-radius: 50%;
      }

      &--kind-workshops {
        padding: 0;
        padding: 0.5rem 0;
        margin-left: 0px;
        border-radius: 0;

        .content {
          .header {
            font-size: 14px;
          }

          .description {
            font-size: 12.5px;
            padding-top: 0.2rem;
            color: rgba(0, 0, 0, 0.7);
          }

          .datetime {
            display: flex;
            flex-direction: column;
            padding-top: 0.3rem;
            justify-content: space-between;
            height: 31px;
            font-size: 11.5px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}
