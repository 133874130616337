.extendable-button {
  overflow: hidden;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & .extendable-button-label {
    transition: all 0.25s ease-in-out;
    display: inline-block;
    max-width: 0;
    padding-left: 0;
    overflow: hidden;
  }
  &__open {
    .extendable-button-label {
      padding-left: 10px;
      max-width: 220px;
    }
  }
  & > .icon {
    margin: 0;
  }
}
