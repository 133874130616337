.block--banner {
  video {
    width: 100%;
    height: auto;
    display: block;
  }
  image {
    display: block;
  }
}
