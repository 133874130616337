.WorkshopAccordionCard {
    display: flex;
    width: calc(100% - 2em);
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    margin-left: 1em;
    margin-right: 1em;

    &__accordion {
        flex: 5;
        padding-right: 1em;

        .AccordionDetails {
            &__header {
                margin-top: 20px;
            }

            &__title {
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 0 10px 0;

                i {
                    margin-right: 10px;
                }

                .ui.header.title {
                    margin: 0px;
                    flex: 1;
                }

                .category {
                    margin-left: 8px;
                    padding: 10px !important;
                    border: 1px solid black;

                }
            }

            &__details {
                padding: 10px;
                padding-top: 0px;
            }
        }
    }

    &__actions {
        flex: 2;
        display: flex;
        flex-direction: column;
        padding: 0.5em;
        text-align: right;

        .WorkshopSessionButton, .WorkshopAddToCalendarButton, .WorkshopAddToCalendarButton {
            &.ui.button {
                border-radius: 10px;
                padding: 1em!important;
                text-align: left;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width: 480px) {
    .WorkshopAccordionCard {
        flex-direction: column;
        width: inherit;

        &__accordion {
            flex: 1;
        }
        &__actions {
            flex: 1;
        }
    }
}
