@import "../../../../scss/helpers/positions";

.IconGridItems--variant-gridLayout,
.IconGridItems--variant-grid {
  display: grid;
}

.IconGridItem--variant-gridLayout,
.IconGridItem--variant-grid {
  .IconGridItem {
    &__Wrapper {
      height: 100%;
    }

    &__Image {
      > img {
        width: 100%;
        height: 100%;
        @include pos-absolute(0, 0, 0, 0);
      }
    }

    &__Content {
      background: rgba(0, 0, 0, 0.25);
      @include pos-absolute(0, 0, 0, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      color: white;
    }
    &__Icon {
      font-size: 2em;
    }

    &__Title {
      font-size: 1.1em;
      text-align: center;

      &:after {
        display: block;
        height: 2px;
        width: calc(100% - 8px);
        margin: 0 auto;
      }
    }
  }
}
