.ReactVideoPlayer {
    position: relative;
    padding-bottom: 56.25%; // 16/9
    padding-top: 25px;
    height: 0px;

    >div {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
    }
}