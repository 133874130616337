.day-calendar {
    &.ui.segment {
        width: 100%;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    a.agenda {
        margin-top: 10px;
        border-radius: 20px;
        width: 100%;
    }

    &__days {
        h3 {
            font-size: 1.2em;
        }

        > .divider {
            margin: 30px 30px 0px 30px;
            border-top: 2px solid #aaa;
        }

        &__hours {
            .hour {
                display: flex;
                align-items: center;

                > .divider {
                    height: 1px;
                    border-top: 1px dashed #000;

                    &--left {
                        width: 30px;
                        margin-right: 10px;
                    }

                    &--right {
                        flex: 1;
                        margin-left: 10px;
                    }
                }

                h4 {
                    margin-top: 1em;
                    font-size: 1em;
                }
            }

            &__event {
                cursor: pointer;
                position: relative;
                padding: 10px;
                background-color: #ddd;
                margin-bottom: 10px;
                border-radius: 8px;

                &__time {
                    font-size: 0.9em;
                    margin: 0px;
                }

                &__title {
                    margin: 0px;
                    font-size: 1.2em;

                    &.is-mobile {
                        font-size: 1em;
                        margin-bottom: 4px;
                    }
                }

                &__type {
                    color: white;
                    text-transform: uppercase;
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    padding: 4px 15px;
                    font-size: 0.85em;

                    &.is-mobile {
                        position: relative;
                        top: unset;
                        right: unset;
                        font-size: 0.75em;
                    }
                }
            }
        }
    }

    &__popup {
        .header {
            display: flex;
            justify-content: space-between;

            @media (max-width: 480px) {
                display: block;
                margin-top: 2em;
            }
        }

        .action-container {
            flex-shrink: 0;
            margin-left: 0;

            @media (max-width: 480px) {
                margin-left: 0;
                margin-bottom: 1em;
            }
        }

        .datetime {
            font-size: 1.3em;
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        hr {
            margin: 0px 5%;
            border: 1px solid #aaa;
        }

        .ui.label.type {
            margin-left: 0px;
        }

        .prepare,
        .cancel {
            margin-top: 20px;

            i {
                margin-right: 4px;
                font-size: 20px;
            }

            .header {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 0px;
            }

            .ui.button {
                background-color: transparent;
                padding: 0px;
                margin: 0px;
                text-decoration: underline;

                &.cancel,
                &.update {
                    font-weight: normal;
                    font-size: 12px;
                    margin-right: 8px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .day-calendar {
        &__days {
            &__hours {
                &__event {
                    &__type {
                        position: relative;
                        top: unset;
                        right: unset;
                    }
                }
            }
        }
    }
}
