$green: #4ecb71;
$red: #d6623e;
$orange: #f0b25b;

.ChatMeetingCard {
  &.ui.card {
    width: 400px;
    margin-bottom: 0px;

    @media (max-width: 768px) {
      width: fit-content !important;
    }
  }

  &--status {
    &-pending {
      .header .icon {
        background-color: $orange;
      }
    }

    &-canceled,
    &-refused {
      .header .icon {
        background-color: $red;
      }
    }

    &-confirmed {
      .header .icon {
        background-color: $green;
      }
    }
  }

  .content {
    &:first-child {
      padding: 0.5em 1em 0.3em 1em;
    }
  }

  .header {
    display: flex !important;
    font-size: 1em !important;
    align-items: center;

    .icon {
      color: white;
      margin-right: 1em;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border-radius: 50%;
    }
  }

  &__Info {
    color: black !important;
    span.label {
      font-weight: bold;
    }
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0.4em;
    &.owner,
    &.invitee {
      .btn-cancel,
      .btn-reject {
        margin-left: 0px;
        min-width: max-content;
        color: white;
        background-color: $red;
      }

      .btn-confirm {
        margin-left: 1em;
        min-width: max-content;
        color: white;
        background-color: $green;
      }

      .WorkshopAddToCalendarBlock--Dropdown {
        margin-left: 4px;
        background-color: white !important;
        color: #777;
        border: 1px solid #777;

        &:hover {
          color: black !important;
        }
      }
    }
  }
}
