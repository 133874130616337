.block--image-with-icons {
  &.ui.segments {
    > .ui.segment:last-child {
      padding: 0;
    }
  }
}

.image__icons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &__button {
    display: block;
    position: absolute;
    cursor: pointer;
    transition: transform var(--ac-transition-normal) ease-in-out;

    &.labeled {
      display: flex;
      align-items: center;
    }

    &--label {
      &.ui.header {
        margin: 0px;
        margin-right: 10px;
      }
    }

    &:hover {
      transform: scale(1.1);
      transition: transform 0.1s ease-in-out;
    }
  }
}
