.ui.cards a.workshop--variant-image-overlay:hover {
  border: 0;
  box-shadow: none;
}

.ui.card.workshop--variant-image-overlay {
  position: relative;
  border: 0;
  box-shadow: none;
  border-radius: 0;

  &.hasImage {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .image {
    border-radius: 0 !important;
  }

  .overlay-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &--tagPosition {
      &-inside-overlay {
        h2 {
          margin-bottom: 4px;
          text-align: left;
        }

        .tags {
          text-align: left;

          &--tag {
            border-radius: 20px;
          }
        }
      }

      &-outside-overlay {
        .tags {
          position: absolute;
          top: 4px;
          left: 4px;
          width: calc(100% - 26px);

          &--tag {
            border-radius: 20px;
          }
        }

        i.icon.heart {
          position: absolute;
          top: 4px;
          right: 2px;
          color: white;
        }

        .duration {
          position: absolute;
          bottom: 0px;
          right: 0px;
          color: white;
          line-height: 1;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 0.2em 0.4em;
          font-size: 0.9em;
        }

      }
    }

    .overlay {
      background: rgba(0, 0, 0, 0.7);
      color: white;
      text-align: center;
      padding: 0.75em;

      >h2 {
        font-size: 1.25rem;
      }
    }
  }
}