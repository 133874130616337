.ui.card.card--video {
  width: 260px;
  color: rgba(0, 0, 0, 0.8);


  .content.card--video-info {
    line-height: 25px;
    padding: 0.5em;
    display: flex;
  }

  .card--video-title {
    width: 95%;
  }

}