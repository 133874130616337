.CloudTvRegistration {
  .WorkshopsRegistrationBlock {
    font-size: 0.85em;

    .Workshop__Container {
      &--Image {
        min-height: 100px;
        width: 240px;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .date,
      .category,
      .interested-by-workshop-label {
        display: none;
      }
    }
  }
}
