.ui.icon.menu,
.ui.menu {
  .item.sidebar-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: left;
    // color: white;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
    margin-bottom: 8px;

    padding-top: 16px;

    .user-title {
      font-size: 1.2em;
      opacity: 0.95;
      padding: 4px 0;
    }
    .user-subtitle {
      font-size: 1.1em;
      opacity: 0.9;
      padding: 4px 0;
    }

    .user-thumbnail {
      margin-bottom: 4px;
    }

    i.user.icon {
      font-size: 64px;
      margin-bottom: 4px;
      // flex-grow: 0;
      // margin: 0 !important;
      // margin-right: 8px !important;
    }
  }
}
