.block--cloudtv {
    .currentLive {
        margin-bottom: 10px;
        font-size: 1em;

        span.live {
            padding: 0px 8px;
            background-color: red;
            color: white;
            margin-right: 4px;
        }

        span.watch-live {
            margin-right: 8px;
        }
    }

    .actions {
        text-align: right;
        margin-top: 10px;

        .ui.button {
            margin-left: 4px;
            margin-right: 0px;
        }
    }
}