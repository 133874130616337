@media (max-width: 767px) {
  .menu-container {
    padding-top: 10px; // TODO: remove when safe to delete ?

    &--fixed-mobile {
      padding-top: 50px;
    }
  }
}

.sidebar--menu {
  &--fixed {
    background: rgb(255, 255, 255);
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    box-shadow: 0 0 6px rgb(0, 0, 0);
  }

  &__header {
    width: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    > i.icon {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }

    @media (max-width: 767px) {
      > i.icon {
        font-size: 20px;
        margin: 0 !important;
        width: initial;
        margin-left: 15px !important;
        margin-right: 15px !important;
      }
    }

    @media (max-width: 480px) {
      .item.secondary-menu__item {
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        width: 80%;
      }
    }
  }

  .screen-overlay {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity var(--ac-transition-normal) ease-in-out;
    pointer-events: none;
    z-index: 101;

    &.is-visible {
      pointer-events: initial;
      opacity: 1;
    }

    @media (max-width: 767px) {
      display: block;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__container {
    background: #fff;
    width: 300px !important;

    &--header {
      box-shadow: 0px 0px 6px #000e9c33;
      text-align: center;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .icon.times {
        font-size: 20px;
      }
    }

    > .ui.menu {
      border: 0;
      box-shadow: none;
      text-align: center;
      width: 100% !important;
      border-radius: 0px;
      margin: 0px;
      display: flex !important;
      flex-direction: column !important;
    }

    .dropdown--container {
      padding: 8px 16px;
    }
  }

  &__search--bar {
    width: 100%;

    & .input {
      width: 100%;
    }
  }

  .ui.vertical.menu {
    &.mobile-menu {
      &__secondary {
        border-top: 1px solid rgba(34, 36, 38, 0.1);

        .item {
          text-align: left;

          &--MessagesPopup,
          &--NotificationsPopup {
            .ui.image.image-icon {
              margin-right: 20px;
              display: inline;
            }
          }
        }
      }
    }
  }

  .mobile-menu {
    &__item {
      display: flex !important;
      align-items: flex-start;
      justify-content: flex-start;

      > .subitem {
        padding: 0.5em 2em;
        width: 100%;
        border: 2px solid rgba(34, 36, 38, 0.1);
      }
    }
  }
}
