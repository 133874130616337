// respond is the name of your mixin

@use "sass:math";

@mixin respond($breakpoint) {
  // $breakpoint is simply a variable that can have several values

  @if $breakpoint==tablet {
    // here `laptop` is the value of $breakpoint
    // when call laptop, we mean the following piece of code

    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
}

@mixin grid($itemsPerRow, $gap) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -$gap * 0.5;

  > * {
    width: calc(#{math.div(100%, $itemsPerRow)} - #{$gap});
    // flex: 1;
    flex-shrink: 0;
    margin: $gap * 0.5;
  }

  @include respond(tablet) {
    > * {
      width: calc(#{math.div(100%, round($itemsPerRow * 0.5))} - #{$gap});
    }
  }

  @include respond(mobile) {
    > * {
      width: calc(100% - #{$gap});
    }
  }
}
