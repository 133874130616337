.ui.card.FileCard {
  .content {
    flex: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    .header {
      margin-top: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

  }
}
