#root>.page {
  flex: 1;
  display: flex;
  flex-direction: column;

  .page--cms-container {
    flex-grow: 1;
  }
}

.page--fullheight {
  height: 100vh;
  overflow-y: hidden;

  .page--cms-container {
    display: flex;
    flex-direction: column;
    height: 0;

    >.content {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 0;

      .ui.container {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 0px;
      }
    }

  }
}