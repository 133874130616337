.AgendaWeek {
  .AgendaDay {
    &__header {
      font-size: 1em;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 1em;

      sup {
        top: -0.25em;
      }
    }
  }

  .AgendaSession {
    margin-top: 8px;
    margin-bottom: 8px;

    &__time {
      font-size: 11px;
    }

    &__content {
      display: block;
      color: rgba(0, 0, 0, 0.9);
      border-radius: 4px;
      background: rgb(240, 244, 248);
      padding: 0.75em;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    &__title {
      font-size: 14px;
      // font-weight: bold;
      // text-transform: uppercase;
    }

    &__shortDescription {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.8);
      // font-weight: bold;
      // text-transform: uppercase;
    }

    &__tag {
      text-transform: uppercase;
      font-size: 10px;
    }
  }
}
