@keyframes CTATile-anim--in-opacity-scroll-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ui[class*="two column"].grid > .column:not(.row).doubling {
  width: 100%;
}
