$highlight-color: #7e83b2;
$highlight-border-color: #5865f2;
$highlight-text-color: #dcdefa;

.emote-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  column-gap: 8px;
  row-gap: 4px;
  z-index: var(--emote-list-index);
  flex-wrap: wrap;

  .reaction {
    &.ui.button {
      border-radius: 20px;
      color: unset !important;
      margin: 0 !important;
      font-weight: normal;
      padding: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &.ui.button.disabled {
      opacity: 0.9 !important;
    }
    &.active {
      background-color: $highlight-color !important;
      box-shadow: inset 0px 0px 0px 1px $highlight-border-color !important;
      & span {
        color: $highlight-text-color;
      }
    }
    .reaction-see-more-label {
      padding: 0 4px;
      font-weight: bold;
    }
  }

  &__count {
    padding: 0 2px;
  }
}

.media-card {
  .reaction.ui.button {
    font-size: 11px;
  }
}
