.publication-details {
  position: relative !important;
  overflow: hidden;

  & .detailed-header {
    position: absolute;
    top: 0;
    & .buttons-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &--mobile {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    border-radius: 0 !important;
    & .publication-comments {
      width: 100%;
      padding-bottom: 80px;
    }
    & .keyboard-toolbar {
      background: white;
      z-index: 100;
      transition: box-shadow 250ms;
    }
  }
  &--desktop {
    height: 600px;
    border-radius: 10px !important;
    width: 70%;
    & .publication-comments {
      width: 100%;
      padding: 0px 15px;
    }
    .navigation-button.ui.icon.button {
      position: absolute;
      transition: opacity 250ms;

      &:hover {
        background-color: rgba(192, 192, 192, 0.6);
        color: white;
      }

      &.prev-button {
        left: 10px;
        opacity: 0.3;
      }

      &.next-button {
        right: 10px;
        opacity: 0.3;
      }
    }
  }
  & .navigation-button.ui.icon.button {
    background-color: rgba(192, 192, 192, 0.4);
    display: flex;
    color: white;
    padding: 0px !important;
    border-radius: 20px;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
  }

  & .comment-input-wrapper {
    &:has(textarea:focus) {
      box-shadow: 0px 0px 5px 0px lightgrey;
    }
  }
}

.navigation-button-container {
  display: flex;

  .navigation-button.ui.icon.button {
    position: initial;
  }
}

.publication-details--mobile {
  .locked-commment-input {
    position: absolute;
    bottom: 0;
    z-index: var(--ac-index-pop-up);
    width: 100%;
    background-color: white;
  }
}

.publication-details--mobile:has(video) {
  background: black !important;
}

.dimmer:has(.publication-details--mobile) {
  padding: 0 !important;
}

.detailed-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  z-index: 2;
  border-radius: 0 !important;

  &--gradient {
    background: linear-gradient(180deg, rgba(80, 80, 80, 0.5), transparent);
    padding: 12px 12px 30px 12px;
  }

  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 12px;
  }
  .popup-trigger {
    background: transparent !important;
    & span {
      color: white !important;
    }
  }
  &--dark {
    .popup-trigger span {
      color: #373737 !important;
    }
  }
}

.actions-popup {
  border-radius: 10px !important;
  padding: 0 !important;
}
.actions-panel {
  & .actions-wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    & button:first-child {
      border-radius: 10px 10px 0 0;
    }
    & button:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.detailed-header,
.publication-container__header {
  & .close-button {
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid white;
    color: white;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    &--dark {
      border: 1px solid dimgrey !important;
      color: dimgrey !important;
    }
  }
}

.publication-container {
  display: flex !important;
  flex-direction: row;
  width: 100%;
  height: 100%;

  &__header {
    width: 100%;
    padding: 16px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    &--gradient {
      background: linear-gradient(180deg, rgba(50, 50, 50, 0.5), transparent);
    }
  }

  &__content {
    flex: 1;
    background: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    border-right: 1px solid rgb(230, 230, 230);
    &:hover {
      & .prev-button,
      .next-button {
        opacity: 1 !important;
      }
    }
    & .image-media {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
    & .video-wrapper {
      width: 100%;
      height: 100%;
      padding-bottom: 40px;
      &:has(video:fullscreen) {
        padding-bottom: 0px !important;
      }
    }
    & video {
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      width: 100%;
    }

    & .emote-list {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 16px;
      background: linear-gradient(0deg, rgba(50, 50, 50, 0.5), transparent);
      width: 100%;
    }
  }
  &__text {
    background: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    line-height: 20px;
    font-size: 16px;
  }
  &__side {
    width: 350px;
    position: relative;
    .publication-content {
      padding: 5px 10px !important;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .publication-details__desktop {
    width: 70% !important;
    margin: 0;
  }
}

.animated-modal {
  animation: slide ease-out;
  animation-duration: 250ms !important;
}

@keyframes slide {
  0% {
    opacity: 0.7;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
