.modal.modal--videoChatModal {
  .content {
    padding: 0;
    height: 70vh;
  }
  iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }

  .icon.close {
    top: -1.5em;
    right: -1.5em;
  }
}
