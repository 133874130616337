.NotificationsPopup {
    position: relative;

    &.ui.popup {
        width: 270px;
        max-width: 270px;
        padding: 0px;
    }

    &__header {
        &.header {
            margin-top: 0.5em !important;
            margin-bottom: 0.3em !important;
        }
    }


    &__button {
        &.ui.button {
            background-color: transparent;
            position: relative;
            z-index: 100;

            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }

    &__counter {
        background-color: red;
        color: white;
        width: 18px;
        height: 18px;
        position: absolute;
        top: 6px;
        right: 10px;
        border-radius: 50%;
        line-height: 1;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px
    }

    &__List {
        padding: 1em;
        max-height: 75vh;
        overflow-y: auto;

        &__see-all-button {
            &.ui.button {
                font-weight: normal;
            }
        }
    }
}