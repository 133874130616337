.VideoModal {
  width: 100%;
  margin-left: 50px;
  margin-right: 50px;

  &.legacy {
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

  &--Video {
    width: 100%;
    max-height: 85vh;
  }

  &--Header {
    text-align: center;
  }

  .close.icon {
    cursor: pointer;
    position: absolute;
    top: -1.5rem !important;
    right: -1.5rem !important;
    z-index: 1;
  }

  .ui.embed video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
