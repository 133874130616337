.workshop-card-ovh {
  width: 100%;

  &__container {
    display: flex;
  }

  .ui.card {
    width: 100%;

    .image {
      position: relative;
      margin-right: 20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 280px;
      width: 280px;
      min-height: 170px;

      .overlay {
        visibility: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background-color: rgba(255, 255, 255, 0.4);

        .actions {
          .action.ui.button {
            padding-right: 2.5em !important;
            padding-left: 1em !important;
          }
        }
      }

      &:hover {
        .overlay {
          visibility: visible;
        }
      }
    }

    .tags {
      margin-top: 10px;
    }
  }
}

@media (max-width: 767px) {
  .workshop-card-ovh {
    &__container {
      flex-direction: column;
    }

    &__content {
      margin-top: 10px;
    }

    .ui.card {
      width: 100%;

      .image {
        width: 100%;
      }
    }
  }
}
