.image-ratio-wrapper {
  position: relative;
  overflow: hidden;

  > img {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
  }
}

.SocialWall {
  &__Wrapper {
    &.is-fullscreen {
      padding: 16px;
    }
  }

  &--Card {
    &__Container {
      margin: 4px;
      background-color: white;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      overflow: hidden;
    }

    &__Image {
      cursor: pointer;
    }

    &__Message {
      color: black;
      font-size: 2vh;

      &.quoted {
        margin: 8px 0px;
      }
    }

    &__User {
      display: flex;
      align-items: center;
      margin-top: 16px;

      .ui.image.thumbnail {
        margin-right: 8px;
      }

      .fullname {
        font-weight: bold;
      }

      .date {
        color: #999;

        i {
          margin-right: 4px;
        }
      }
    }

    &__Actions {
      hr {
        border-bottom-color: #ddd;
      }

      i {
        cursor: pointer;
      }

      &--reactions {
        flex: 1;
        display: flex;

        &__reaction {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .count {
            font-weight: bold;
          }
        }
      }

      &--edition {
        i.pencil {
          margin-right: 10px;
        }
      }
    }
  }

  &--Button.ui.button {
    background-color: white;
    margin-bottom: 10px;
    color: black;
  }

  .button--label {
    padding-right: 20px;
  }

  &--Masonry {
    outline: none;
  }

  &--Modal {
    &__Content {
      &.content {
        padding: 0px !important;
        height: 60vh;
      }

      &--Columns {
        height: inherit;
        display: flex;
      }

      &--Image {
        width: 700px;
        background-color: #f5f6f7;
        padding: 20px;
      }

      &--Message {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;

        .location {
          background-color: white;
          color: black;
          border: none;
          padding: 0px;
          margin-bottom: 10px;
          max-width: max-content;

          &:hover,
          &:focus {
            background-color: white;
          }

          &--input {
            width: 100%;
            margin-bottom: 10px;
          }
        }

        textarea.message {
          border: none;
          padding: 0px;
          min-height: 30vh;
          font-size: 18px;
          margin-bottom: 10px;
          font-family: Tahoma;
          flex: 1;
        }
      }
    }
  }

  &--Error {
    &__HighlightedText {
      font-weight: bold;
      margin: 0px 5px;
    }
  }

  &__Metadata {
    transition: opacity var(--ac-transition-fast), background-color var(--ac-transition-normal);
    background-color: transparent;
    opacity: 0;
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      transition: opacity var(--ac-transition-fast) background-color var(--ac-transition-normal);
      background: black;
      color: white;
      opacity: 0.7;
    }

    & .reaction {
      margin: 5px;
      font-size: 1.2em;
    }

    & .icon {
      margin: auto;
      display: block;
    }

    & .count {
      text-align: center;
      font-weight: bold;
    }
  }
}

@media (max-width: 480px) {
  // Eat double-padding
  .page__wrapper > .block--socialWall {
    margin-left: -1em;
    margin-right: -1em;
  }

  .SocialWall--Button.button--mobile-action.u-mobile-only {
    width: initial;
    border-radius: 100px !important;

    .button--label {
      overflow: hidden;
      white-space: nowrap;

      transition: max-width var(--ac-transition-normal) ease-in-out,
        padding-right var(--ac-transition-normal) ease-in-out;
      max-width: 220px;
      display: inline-block;
    }

    .icon {
      width: 44px;
      vertical-align: top;
    }
  }

  .page--scrolled {
    .SocialWall--Button.button--mobile-action.u-mobile-only {
      .button--label {
        max-width: 0;
        padding-right: 0;
        // display: none;
      }
    }
  }

  .SocialWall {
    &--Modal {
      &__Content {
        &.content {
          max-height: 80vh !important;
          height: 80vh;
          overflow-y: hidden !important;
        }

        &--Columns {
          display: flex !important;
          flex-direction: column !important;
          height: 100% !important;
        }

        &--Image {
          width: 100%;
          height: 30vh;
        }

        &--Message {
          .ui.form {
            display: flex;
            flex-direction: column;

            textarea.message {
              min-height: unset;
              flex: 1;
            }
          }
        }
      }
    }

    &__Metadata {
      display: none !important;
    }

    &--Card__Actions--reactions__reaction {
      padding: 0.5em;
    }
  }
}
