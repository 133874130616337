.ui.menu.menu--filters-top {
  > .item {
    padding-top: 0;
    padding-bottom: 0;

    &.menu-filter--search {
      padding: 0 1px;
      input {
        height: 100%;
        border: 0;
      }
    }
  }
}
