.ui.list > .item.WorkshopsBlock__Item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .image {
    display: block;
    width: 60px;
    height: auto;
  }

  .content > .header {
    font-weight: normal;
  }

  .description {
    margin-top: 2px;
    > .label {
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
}
