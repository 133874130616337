.block--social-wall {
  display: flex;

  .header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    > .header {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .segment--social-wall {
    height: 200px;
    flex-grow: 1;
    overflow: auto;
  }

  .SocialWall--Card__User {
    margin-top: 0;
  }
}
