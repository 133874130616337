.SidebarMenuBlock__Item {
  border: 0;
  padding: 0.75em 1.5em;
  width: 100%;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  color: initial;
  display: block;
  font-size: 14px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

  &:hover {
    color: initial;
  }

  &.is-active {
    border-left-color: #777;
    font-weight: bold;
  }
}
