.vertical-menu {
    &.ui.menu {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

        .item {
            padding: 1em 0px 1em 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                .vertical-menu__item__icon>.icon {
                    display: block;
                }
            }
        }
    }

    &__item {
        &__value {
            &--withValue {
                width: 36px;
            }

            margin: 0px;
            margin-right: 10px;
            font-size: 2em;
            text-align: center;

            &--right {
                text-align: right;
            }
        }

        &__content {
            flex: 1;
        }
        &__title {
            font-size: 1.1em;
            margin: 0px;
        }

        &__subtitle {
            margin: 0px;
            margin-top: 4px;
        }

        &__icon {
            min-width: 25px;
            width: 25px;
            text-align: center;
            >.icon {
                display: none;
            }
        }

    }
}
