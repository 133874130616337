.workshop--card3 {
  display: flex;
  align-items: top;
  // TODO: prevent last elements from growing ?
  // flex: 1 1 270px;
  width: 270px;
  margin-right: 12px;

  &>.ui.card {
    margin-top: 40px;
    margin-bottom: 10px;
    width: 100%;

    .ui.tiny.progress .bar {
      min-width: 0em;
    }

    &>.card__image {
      padding: 8px;
      position: absolute;
      top: -36px;
      left: 0;
      right: 0;

      .image-with-shadow {
        z-index: 1;
        background-size: cover;
        background-position: center;
      }
    }

    .location {
      color: #999;
      font-size: 12px;
    }
  }

  .ui.card>.content {
    flex-grow: 0;
  }

  .workshop--category {
    line-height: 1.5;
  }

  .workshop-card-actions {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .workshop--card3 {
    &.no-image {
      >.ui.card {
        margin-top: 0px !important;
      }
    }
  }
}