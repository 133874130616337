.no-items-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  padding: 20px;
  & span {
    color: gray;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }

  & button {
    padding: 10px 20px;
    box-shadow: none;
    border: 0;
    border-radius: 50px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    margin-top: 8px;
    background-color: var(--ac-color-primary);
    color: white;
    &:hover {
      opacity: 0.8;
    }
  }
}
