.publication-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  line-height: 16px;
  &__date-small {
    font-size: 12px;
  }
  &__date {
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
    gap: 4px;
    color: white;
    &--dark {
      color: grey;
    }
  }
}
