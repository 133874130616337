.home-block--workshops,
.home-block--upcoming-workshops {
  > .column > .ui.segment {
    background: var(--ac-color-white-1);

    .workshop--card3 {
      flex: 1 0;
      min-width: 270px;
      max-width: 360px;
      &:first-child {
        margin-left: 12px;
      }
    }
  }
}
