.home__upcoming {
  display: flex;
  flex-direction: column;

  &__sessions {
    flex: 200px 1;
    overflow-y: auto;
  }

  .ui.button.view-all-sessions {
    display: none;
  }
}