.WorkshopRegistationButton {
    &__AlreadyRegistered {
        .already-registered-label {
            text-align: right;
            color: red;
        }

        .ui.button.unregister-button {
            background-color: rgba(66, 186, 150, 0.15);
            color: #42ba96;
            width: 150px;

            .not-hovered {
                display: block;
            }

            .hovered {
                display: none;
            }


            &:hover {
                background-color: #db2728;
                color: white;

                .not-hovered {
                    display: none;
                }

                .hovered {
                    display: block;
                }
            }

        }

    }

    &__Unregistered {
        .interested-by-workshop-label {
            text-align: right;
        }

        .register-button {
            width: 150px;
            text-transform: uppercase;
        }
    }

}