.text-wrapper {
  width: 100%;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.see-more-btn {
  font-size: 12px;
  color: #342e60;
  border: none;
  background-color: transparent;
  align-self: flex-end;
  margin: 5px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
