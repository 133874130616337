$gold: #F3C845;
$silver: #C6D1DC;
$bronze: #DAB187;

.CmsPageLayout {
    &--theme {
        &-light {
            .Podium {
                color: black;

                .user {
                    background-color: #eaeaea;

                    &.first {
                        background-color: #dedede;
                    }

                    .infos {
                        .company {
                            color: #777;
                        }
                    }
                }
            }
        }

        &-dark {
            .Podium {
                color: white;

                .user {
                    background-color: #1E2237;

                    &.first {
                        background-color: #252A40;
                    }

                    .infos {
                        .company {
                            color: #B7B3B3;
                        }
                    }
                }
            }
        }
    }
}


.Podium {
    display: flex;
    margin-top: 60px;

    .user {
        flex: 1;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 0.5em;
        display: flex;
        flex-direction: column;

        .thumbnail {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100px;
            margin-top: -50px;


            img.avatar {
                margin: auto;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-bottom: 1em;
                border: 3px solid white;
                object-fit: cover;
            }

            img.crown {
                margin-bottom: 4px;
            }

            .rank {
                position: absolute;
                bottom: 6px;
                margin: auto;
                text-align: center;
                border-radius: 50%;
                left: calc(50% - 9px);
                right: calc(50% - 9px);
                width: 20px;
                height: 20px;
                font-size: 12px;
                font-weight: bold;
            }
        }

        &.first {
            border-radius: 12px;

            .thumbnail {
                img.avatar {
                    border-color: $gold;
                }

                .rank {
                    background-color: $gold;
                }
            }

            .score {
                margin: 1em 0em;
                font-weight: bold;
            }
        }

        &.second {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            margin: 10px 0px;

            .thumbnail {
                img.avatar {
                    border-color: $silver;
                }

                .rank {
                    background-color: $silver;
                }

            }
        }

        &.third {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            margin: 10px 0px;

            .thumbnail {
                img {
                    border-color: $bronze;
                }

                .rank {
                    background-color: $bronze;
                }
            }
        }

        .infos {
            flex: 1;

            .firstName,
            .lastName {
                font-weight: bold;
            }

            .lastName {
                text-transform: uppercase;
            }

            .company {
                margin-top: 0.5em;
            }
        }

        .score {
            margin: 0.6em 0em;
            font-weight: bold;
        }
    }
}