.publication-comments {
  padding: 12px 20px 12px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  & .comments-count {
    margin-bottom: 16px;
  }

  &__container {
    flex: 1;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 767px) {
  .publication-comments {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.comment-container {
  margin-bottom: 20px;
  & .detailed-header {
    position: unset !important;
    margin-bottom: 8px;
    padding: 0;
  }
  & .comment-content {
    margin-left: 50px;
    white-space: pre-line;
    line-height: 1.33;
  }
}

.comment-input {
  position: relative;
  padding: 20px;

  &--bigger {
    & textarea {
      padding-right: 75px !important;
    }
  }
  & textarea {
    width: 100%;
    background: #f6f5f8;
    border-radius: 12px;
    border: 0;
    resize: none;
    padding: 10px;
    padding-right: 40px;
    outline: none;
    min-height: 40px;
    font-size: 16px;
  }
  &__buttons {
    position: absolute;
    bottom: 25px;
    right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  & .send-button {
    border-radius: 30px;
    background: transparent;

    &:disabled {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
  & .popup-trigger {
    background: transparent !important;
  }
  & button {
    padding: 0 !important;
    border: 0;
    cursor: pointer;
  }
}
.close-header {
  color: grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px 0 20px;
  gap: 10px;
  cursor: pointer;

  & button {
    background-color: transparent;
    border-radius: 20px;
    border: 1px solid grey;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
