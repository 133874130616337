.container--square {
  width: 100%;
  padding-bottom: 100%;
  position: relative;

  &>div {
    position: absolute;
    left: 0;
    top :0;
    right: 0;
    bottom: 0;
  }
}