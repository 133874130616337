.block-container--segment.block--VideoBlock {
  .segment--content {
    padding: 0;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
