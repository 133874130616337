.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;



  &--label-outside {
    margin-top: 15px;
    text-transform: uppercase;
    font-size: 20px;
  }


}

.countdown-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px 40px 0 40px;
  position: relative;

  .label {
    .hour {
      font-size: 48px;
      line-height: 38px;
    }
  }

  &.curved {
    span {
      display: none;
    }
  }

  &--no-label {
    padding-top: 0;
  }
}

.countdown-item span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}


@media (max-width: 970px) {
  .countdown-item {
    margin: 7px;
  }
}

@media (max-width: 504px) {
  .countdown-item {
    margin: 27px;
  }
}

@media (max-width: 480px) {
  .countdown-item {
    font-size: 30px;
    margin: 23px;
  }
}

@media (max-width: 340px) {
  .countdown-item {
    margin: 12px;
  }
}