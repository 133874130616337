.CmsPageLayout {
  &--theme {
    &-dark {
      .Leaderboard {
        &__Menu {
          &.ui.menu {
            background-color: #1e2237;
          }

          .item {
            color: white !important;

            &:hover {
              color: white !important;
            }
          }
        }
      }
    }

    &-light {
      .Leaderboard {
        &__Menu {
          &.ui.menu {
            background-color: #eaeaea;
          }
        }
      }
    }
  }

  .Leaderboard {
    &__Grid {
      &.ui.grid {
        flex: 1;
        height: 0;
        margin-top: 0;
        margin-bottom: 0;
        justify-content: stretch;
        align-items: stretch;
        overflow: hidden;

        > .column {
          display: flex !important;
          flex-direction: column;
          overflow: hidden;
          height: 100%;
        }
      }
    }

    &__GlobalRanking {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 0;
    }
  }
}
