.GridLayout {
  display: grid;
  margin: 20px 0px;
}

@media (max-width: 767px) {
  .GridLayout {
    display: block;
  }
}
