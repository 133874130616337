.ui.card.AchievementToast {
  position: fixed;
  bottom: 10px;
  right: 20%;
  height: 200px;
  width: 300px;
  display: flex;
  justify-content: center;

  > .content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: inherit;

    > .AchievementToast__image {
      flex: 5;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .header, .description {
      text-align: center;
    }
  }

  > .AchievementToast__close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: initial;
    border-radius: 10em !important;
  }

  > .AchievementToast__message {
    position: absolute;
    bottom: 0;
    box-shadow: initial;
  }
}
