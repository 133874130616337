.NetworkingChat {
  &--Container {
    display: flex;
    overflow: hidden;
    flex: 1 1 auto;
  }

  &--ChatWrapper {
    display: flex;
    align-self: stretch;
    flex: 1 1 auto;
    position: relative;

    @media (min-width: 769px) {
      padding-left: 350px;
    }

    // In the main chat, show all messages on the left
    .ui.feed.feed--chat > .event.event--self {
      flex-direction: row !important;
      > .content {
        background-color: rgba(0, 0, 255, 0.125);
      }
    }
  }

  &--Sidebar {
    background-color: white;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  &--SidebarTrigger {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 10;
    box-shadow: 2px 2px 4px black !important;
    @media (min-width: 769px) {
      display: none;
    }
  }
}
