.page--exhibitor.page--template-tabbed {
  .ui.container > .content > .ui.grid {
    margin-left: 0;
    margin-right: 0;
  }

  .banner-logo {
    div.banner {
      padding-top: 25% !important;
      background-size: contain !important;
      background-repeat: no-repeat;
    }

    div.logo {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
      border: none !important;

      img {
        width: 90px;
        object-fit: contain;
      }
    }
  }

  .item.header {
    margin-left: 170px;
    margin-top: -40px;
    margin-bottom: 30px;

    .website {
      border-radius: 20px;
    }
  }

  @media (max-width: 767px) {
    .banner-logo .logo {
      left: 8px !important;
      bottom: -60px !important;

      img {
        height: 65px !important;
        width: 65px !important;
      }
    }

    .item.header {
      margin-left: 0px;
      margin-bottom: 10px;

      a {
        margin-left: 70px;
        font-size: 13px;
      }

      .ui.header {
        padding-top: 8vh;
      }
    }

    // A revoir pour les ipad..
    // .ui.container.page--cms-container {

    //     // padding-left: 3px !important;
    //     // padding-right: 3px !important;
    // }
  }
}
