.tabs {
  &__tab {
    &__html {
      .ui.header {
        margin: 14px 0px;
      }
    }
  }

  @media (max-width: 767px) {
    .ui.pointing.menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 10px;

      .tabs__tab {
        align-self: flex-start;
        padding-left: 5px;
        margin-left: 10px;
      }
    }
  }
}
