.BookAppointmentBlock {
  &--container {
    &__button {
      width: 100%;
    }
  }

  &--slots {
    &__slot {
      &.ui.button {
        border-width: 1px;
        border-style: solid;
        color: black;

        &.active,
        &:hover {
          color: white;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .ui.segment.BookAppointmentBlock {
    &--container {
      text-align: center;
      position: fixed;
      margin: 0px !important;
      left: 0px;
      right: 0px;
      bottom: 0px !important;
      z-index: 10;
      border-radius: 0px;

      &.ui.segment {
        padding-bottom: 48px;
      }

      &__message {
        display: none;
      }
    }

    &--slots {
      &__grid {
        .column {
          width: 100% !important;
        }
      }
    }
  }

  .ui.card.BookAppointmentBlock--meeting {
    width: 100%;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .ui.segment.BookAppointmentBlock {
    &--container {
      position: relative;
    }
  }
}
