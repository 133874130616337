.exhibitor-group {
    &.ui.segment {
        border: none;
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    }

    &__title.ui.header {
        margin: 0px;
        margin-bottom: 4px;
    }

    &__title {
        margin-top: 0px;
    }

    &__cards {
        &.ui.stackable {
            .ui.card {
                padding: 10px !important;
                flex-direction: column;
                display: flex;
            }
        }

        &__card {
            &.ui.card {
                box-shadow: rgba(0, 0, 0, .15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;

            }

            .ui.button.see-more {
                margin: 10px auto !important;
                border-radius: 20px !important;
                padding: 8px 20px;
                width: max-content;
                margin: auto;
                background-color: black;
                color: white;
            }

            &--simple {
                text-align: center;

                .content {
                    padding: 0px;

                    .image {
                        margin: auto;
                        max-height: 60px;
                        object-fit: contain;
                        background-color: white;
                    }

                    .title {
                        margin-top: 8px;
                        margin-bottom: 2px;
                    }

                    .subtitle {
                        font-size: 13px;
                        color: #999;
                        margin: 0px;
                    }

                    .description {
                        margin-top: 10px;
                        display: block;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        max-height: 5.85em;
                        overflow: hidden;

                        p {
                            line-height: 1.2em;
                        }
                    }
                }
            }

            &--matching {
                .content {
                    display: flex;
                    flex-direction: column;
                    padding: 0px;
                    flex: 1;

                    .info {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;


                        .image {
                            max-height: 60px;
                            object-fit: contain;
                            margin-right: 15px;
                            background-color: white;
                        }

                        .title {
                            margin-top: 0px;
                            margin-bottom: 2px;
                        }

                        .subtitle {
                            font-size: 13px;
                            color: #999;
                            margin: 0px;
                        }

                    }

                    .offers {

                        &__stat {
                            margin-top: 8px;

                            .value,
                            .label {
                                margin: 0px;
                            }

                            .label {
                                font-weight: bold;
                            }
                        }

                        &__list {
                            margin-top: 15px;

                            .offer {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-bottom: 1px solid #ddd;
                                padding: 5px 0px;
                                flex: 1;

                                &__progress {
                                    max-width: 40px;
                                    margin-right: 10px;
                                }

                                &__content {
                                    flex: 1;

                                    .title,
                                    .subtitle {
                                        margin: 0px;
                                    }

                                    .subtitle {
                                        color: #777;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

    }
}


@media (max-width: 480px) {
    .exhibitor-group {
        &__cards {
            &__card {
                &.ui.card {
                    margin-left: 0px !important;
                    margin-right: 0px !important;
                    width: 100% !important;
                }

            }
        }
    }
}