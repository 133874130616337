.HeaderBlock {
  .slider {
    .HeaderBlock__slide {
      height: 100%;

      .ui.container {
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .ui.grid {
          height: inherit;
        }
      }

    }
  }

  .carousel-root {
    overflow: hidden;
  }

  .carousel-status {
    display: none
  }

  .carousel .control-dots .dot {
    box-shadow: none;
  }

  .carousel .control-dots {
    margin: 4px 0px;
  }
}

@media (max-width: 480px) {
  .HeaderBlock {
    &__container {
      height: max-content !important;
    }
  }
}