.preReplay--poster {
    position: relative;
    margin: 10px 0px !important;

    .overlay {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }

    .text {
        position: absolute;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: white !important;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }
}