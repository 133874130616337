$gold: #f3c845;
$silver: #c6d1dc;
$bronze: #dab187;

.CmsPageLayout {
  &--theme {
    &-light {
      .RankingList {
        &.ui.list {
          background-color: #eaeaea;

          .item {
            color: black;

            .content {
              .header {
                color: black;
              }

              .description {
                color: #777;
              }
            }

            .rank {
              color: black;
              border-color: black;
            }

            .score {
              color: black;
            }
          }
        }
      }
    }

    &-dark {
      .RankingList {
        &.ui.list {
          background-color: #1e2237;

          .item {
            color: white;

            .content {
              .header {
                color: white;
              }

              .description {
                color: #b7b3b3;
              }
            }

            .rank {
              color: white;
              border-color: white;
            }

            .score {
              color: white;
            }
          }
        }
      }
    }
  }
}

.RankingList {
  &--onlyList {
    &.ui.list {
      .item:first-child {
        .rank {
          background-color: $gold;
          border-color: $gold;
        }
      }

      .item:nth-child(2) {
        .rank {
          background-color: $silver;
          border-color: $silver;
        }
      }

      .item:nth-child(3) {
        .rank {
          background-color: $bronze;
          border-color: $bronze;
        }
      }
    }
  }

  &.ui.list {
    padding: 0.6em 1em 0em 1em;
    border-radius: 12px;
    flex: 1;
    overflow-y: auto;

    @media (max-width: 767px) {
      margin: 1em -1em 0em -1em;
      border-radius: 40px 40px 0px 0px;
    }

    .item {
      display: flex !important;
      align-items: center;
      border-top: 1px solid rgba(95, 89, 89, 0.54);
      padding: 0.7em 1em;

      .thumbnail {
        position: relative;
        margin-right: 0.7em;

        .ui.image.avatar {
          width: 50px;
          height: 50px;
          margin: auto;
          object-fit: cover;
        }

        .you {
          position: absolute;
          color: white;
          bottom: -5px;
          left: 0px;
          right: 0px;
          max-width: max-content;
          font-size: 12px;
          line-height: 1;
          border-radius: 10px;
          text-align: center;
          padding: 2px 0.5em;
          margin: auto;
        }
      }

      .rank {
        width: 30px;
        height: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        border-radius: 50%;
        margin-right: 1.2em;
      }

      .content {
        flex: 1;
        text-align: left;

        .header {
          span.lastName {
            text-transform: uppercase;
          }
        }
      }

      .description.score {
        font-weight: bold;
      }
    }
  }
}
