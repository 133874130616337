.Notification--Button.button--mobile-action {
  width: initial;
  border-radius: 100px !important;

  .button--label {
    overflow: hidden;
    white-space: nowrap;

    transition: max-width var(--ac-transition-normal) ease-in-out,
      padding-right var(--ac-transition-normal) ease-in-out;
    max-width: 220px;
    padding-right: 22px;
    display: inline-block;
  }

  .icon {
    width: 44px;
    vertical-align: top;
  }
}

.page--scrolled {
  .Notification--Button.button--mobile-action {
    .button--label {
      max-width: 0;
      padding-right: 0;
      // display: none;
    }
  }
}
