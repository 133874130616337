@import "../../ContactsBlock/variants/mixins";

.SidebarMenuBlock {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__Menu {
    width: 240px;
    background: white;
    padding: 1.5em 0;
    border-radius: 4px;
    overflow: hidden;
  }

  &__Content {
    flex: 1;
    background: white;
    padding: 1.5em;
    margin-left: 2em;
    border-radius: 4px;
    overflow: hidden;
    min-height: 200px;

    > h2.ui.header {
      margin-top: 0;
    }

    .button--back {
      display: none;
    }
  }
}

@include respond(mobile) {
  .SidebarMenuBlock {
    display: block;

    &.has-selection {
      .SidebarMenuBlock__Menu {
        display: none;
      }
      .SidebarMenuBlock__Content {
        height: calc(100vh - 100px);
        overflow: auto;
        display: flex;
        flex-direction: column;

        > h2.ui.header {
          margin-top: 0.5em;
        }

        .button--back {
          display: block;
        }
      }
    }

    &:not(.has-selection) {
      .SidebarMenuBlock__Content {
        display: none;

        .button--back {
          display: block;
        }
      }
    }

    &__Menu {
      width: 100%;
    }

    &__Content {
      margin-left: 0;
      width: 100%;
    }
  }
}
