.UserInfo__thumbnail {
  margin-right: 8px;
}

.UserInfo__avatar {
  &--placeholder {
    color: white;
    border-radius: 50%;
    text-align: center;

    &.UserInfo__avatar--inverted {
      background: white;
      color: black;
    }
  }
}
