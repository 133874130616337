.opportunity-list-block {
  &__subtitle {
    margin-bottom: 5px;
  }

  &__view-more {
    margin-top: 10px;
    text-align: center;
  }

  &__entry:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  &__entry {
    display: flex;
    cursor: pointer;
    padding: 5px 0;
    flex: 1 1;

    &__progress {
      max-width: 40px;
      margin-right: 10px;
    }

    &__content {
      &__title {
        font-weight: bold;
      }
  
      &__subtitle {
        color: gray;
      }
    }
  }
}